import React from "react";

import {
  HotspotPageData,
  useHotspotPageData,
} from "../../../templates/hotspot";
import { GatsbyContextAmplifier, useGatsbyContext } from "../../../gatsby";
import { Card } from "./card";
import { useIsReady } from "../../ready-state";

const CardVisibilityContext = React.createContext(false);
export const useCardVisibility = () => React.useContext(CardVisibilityContext);
export const CardVisibilityAmplifier = CardVisibilityContext.Provider;

function useCardPageData(): HotspotPageData | undefined {
  const data = useHotspotPageData();

  return React.useMemo(() => {
    if (!data?.file.childHotspot.content) return;
    return data;
  }, [data]);
}

export const CardUI: React.FC<{ prevPath: string | null }> = ({
  children,
  prevPath,
}) => {
  const appIsReady = useIsReady();
  const data = useCardPageData();
  const gatsbyContext = useGatsbyContext();
  const dataRef = React.useRef(data);
  if (data) dataRef.current = data;

  return (
    <GatsbyContextAmplifier
      value={React.useMemo(
        () => ({ ...gatsbyContext, data: dataRef.current }),
        [gatsbyContext]
      )}
    >
      <CardVisibilityContext.Provider value={appIsReady && !!data}>
        <Card prevPath={prevPath} />
        {children}
      </CardVisibilityContext.Provider>
    </GatsbyContextAmplifier>
  );
};
