import React from "react";
import { Object3D, Mesh, SkinnedMesh } from "three";

export * from "./loading";
export * from "./transition";
export * from "./sanitization";
export * from "./material-override";
export * from "./context";

export function isSkinnedMesh(obj: Object3D): obj is SkinnedMesh {
  return obj.type === "SkinnedMesh";
}

export function isMesh(obj: Object3D): obj is Mesh | SkinnedMesh {
  return obj.type === "Mesh" || isSkinnedMesh(obj);
}

export function useMeshModification(scene: Object3D, fn: (mesh: Mesh) => void) {
  React.useEffect(() => {
    scene.traverse((obj) => {
      if (isMesh(obj)) fn(obj);
    });
  }, [scene, fn]);
}

export function useMeshes(obj?: Object3D | null): Mesh[] {
  return React.useMemo(() => {
    const meshes: Mesh[] = [];
    if (obj)
      obj.traverse((obj) => {
        if (isMesh(obj)) meshes.push(obj);
      });
    return meshes;
  }, [obj]);
}
