import React from "react";

import {
  useCurrentHotspot,
  useHotspotContext,
  HotspotNode,
  HotspotNodeObject,
  HotspotData,
} from "./context";

export type HotspotScope<T extends HotspotData = HotspotData> = {
  name: string;
  visible: boolean;
  interactive: boolean;
  active: boolean;
  isParent: boolean;
  hovering: boolean;
  setHovering(value: boolean): void;
  data: T;
};

const Context = React.createContext<HotspotScope | null>(null);

export function useHotspotScope<T extends HotspotData = HotspotData>() {
  return React.useContext(Context) as HotspotScope<T> | null;
}

export type HotspotScopeProps<T extends HotspotData> = {
  name: string;
  disabled?: boolean;
  invisible?: boolean;
  data: T;
};

export function HotspotScopeProvider<T extends HotspotData>({
  children,
  name,
  data,
  invisible,
  disabled,
}: React.PropsWithChildren<HotspotScopeProps<T>>) {
  const ctx = useHotspotContext();

  const currentHotspot = useCurrentHotspot();
  const active = currentHotspot?.name === name;
  const isParent = !!currentHotspot?.findParent(name);

  const visible = !ctx?.invisible && !invisible;
  const interactive =
    visible && !ctx?.disabled && !disabled && !(active || isParent);

  const [hovering, setHovering] = React.useState(false);

  const scope = React.useMemo<HotspotScope<T>>(
    () => ({
      name,
      interactive,
      visible,
      active,
      isParent,
      hovering,
      setHovering,
      data,
    }),
    [name, data, visible, interactive, active, isParent, hovering, setHovering]
  );

  const registerNode = ctx?.registerNode;
  const nodeRef = React.useRef<HotspotNodeObject<T>>(null);
  React.useEffect(() => {
    if (registerNode) return registerNode(nodeRef.current!);
  }, [registerNode]);

  return (
    <HotspotNode<T> name={name} data={data} nodeRef={nodeRef}>
      <Context.Provider value={scope}>{children}</Context.Provider>
    </HotspotNode>
  );
}

export const HotspotScopeAmplifier = Context.Provider;
