import React from "react";
import { Object3D, Vector3, Vector3Tuple } from "three";

import { HotspotScope, useHotspotObjectName, useHotspotScope } from "./hotspot";
import { GlowEffect } from "./glow";
import { Link } from "../../link";
import { GltfContext } from "../../lib/gltf";
import { AnimatedPrimitive, LoopAnimation } from "../../lib/animation";
import { useGltfContext } from "../context";

export function RootObjectMesh({
  object,
  scaleFactor,
  visible,
}: {
  object: Object3D;
  scaleFactor: number;
  visible: boolean;
}) {
  const [position, setPosition] = React.useState<Vector3 | null>(null);

  React.useEffect(() => {
    const pos = object.position.clone();
    setPosition(pos);
    object.position.set(0, 0, 0);
    return () => void object.position.copy(pos);
  }, [object]);

  return (
    position && (
      <group scale={[scaleFactor, scaleFactor, scaleFactor]}>
        <group position={position}>
          <AnimatedPrimitive
            object={object}
            props={{
              scale: new Array(3).fill(visible ? 1 : 0) as Vector3Tuple,
            }}
          />
        </group>
      </group>
    )
  );
}

function HotspotObjectCore({
  ctx: { gltf, scaleFactor },
  scope,
  isButton,
  disableGlow,
  transparent,
}: HotspotObjectProps & { ctx: GltfContext; scope: HotspotScope }) {
  const objectName = useHotspotObjectName();
  const object = gltf.nodes[objectName];

  const elem = (
    <RootObjectMesh
      object={object}
      scaleFactor={scaleFactor}
      visible={scope.visible}
    />
  );

  const loopClip = React.useMemo(
    () => gltf.animations.find((clip) => clip.name == `${objectName}_loop`),
    [gltf, objectName]
  );

  return (
    <>
      {loopClip && (
        <LoopAnimation
          clip={loopClip}
          root={gltf.scene}
          paused={!scope.interactive && !scope.active && !scope.isParent}
        />
      )}
      {!disableGlow && (
        <GlowEffect
          object={object}
          active={scope.hovering}
          transparent={transparent}
        />
      )}
      {isButton ? (
        <Link
          to={`/${scope.name}/`}
          disabled={!scope.interactive}
          setHovering={scope.setHovering}
        >
          {elem}
        </Link>
      ) : (
        elem
      )}
    </>
  );
}

type HotspotObjectProps = {
  isButton?: boolean;
  disableGlow?: boolean;
  transparent?: boolean;
};

export function HotspotRootObject(props: HotspotObjectProps) {
  const ctx = useGltfContext();
  const scope = useHotspotScope();

  return (
    ctx && scope && <HotspotObjectCore {...props} ctx={ctx} scope={scope} />
  );
}
