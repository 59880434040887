import React from "react";
import cn from "classnames";

import { useIsReady } from "../ready-state";
import { BackLink } from "./back-link";
import { UtilityUI } from "./utility";

import * as styles from "./ui.module.css";

export function AsideUI({ prevPath }: { prevPath: string | null }) {
  const isReady = useIsReady();

  return (
    <div className="fixed inset-0 pointer-events-none z-20">
      <div className="absolute left-0 bottom-14">
        <div
          className={cn(styles.anim, (!isReady || !prevPath) && styles.offset)}
        >
          <UtilityUI />
        </div>
      </div>
      <BackLink to={isReady ? prevPath : null} />
    </div>
  );
}
