import React from "react";
import { Color, Object3D } from "three";
import {
  GlowEffect as GlowEffectBase,
  GlowEffectProps as GlowEffectPropsBase,
  useGlowEffectSpring,
} from "../../lib/shader-effects";
import type { StandardMesh } from "../../lib/util";
import { useIsHighlighted } from "./highlight-hinter";
import { useMaterialDuplication } from "../../lib/material-duplication";

export type GlowEffectProps = Omit<GlowEffectPropsBase, "strength" | "mesh"> & {
  object: Object3D;
  active: boolean;
  transparent?: boolean;
};

function TransparentGlowEffect({ mesh, strength = 1 }: GlowEffectPropsBase) {
  const material = useMaterialDuplication(mesh);

  React.useEffect(() => void (mesh.renderOrder = -1), [mesh]);

  React.useEffect(() => {
    material.name = `${material.name}-glow`;
    material.color = material.emissive = new Color(0xffffff);
    material.transparent = true;
    material.alphaTest = 0;
  }, [material]);

  useGlowEffectSpring(
    strength,
    (value) => void (material.opacity = 2.5 * value)
  );

  return null;
}

function GlowEffectCore({
  transparent,
  ...props
}: GlowEffectPropsBase & { transparent?: boolean }) {
  return transparent ? (
    <TransparentGlowEffect {...props} />
  ) : (
    <GlowEffectBase {...props} />
  );
}

export function GlowEffect({ active, transparent, object }: GlowEffectProps) {
  const isHighlighted = useIsHighlighted();
  const strength = active ? 0.1 : isHighlighted ? 0.2 : 0;

  const meshes = React.useMemo<StandardMesh[]>(() => {
    const meshes: StandardMesh[] = [];
    object.traverse((obj) => {
      if (obj.type === "Mesh" || obj.type === "SkinnedMesh")
        meshes.push(obj as StandardMesh);
    });
    return meshes;
  }, [object]);

  return (
    <>
      {meshes.map((mesh, i) => (
        <GlowEffectCore
          transparent={transparent}
          mesh={mesh}
          strength={strength}
          key={i}
        />
      ))}
    </>
  );
}
