import React from "react";
import cn from "classnames";

import * as styles from "./card.module.css";

type Props = {
  className?: string;
  left?: JSX.Element;
  mid?: JSX.Element;
  right?: JSX.Element;
  hidePaddingOnMobile?: boolean;
};

export function CardSection({
  className,
  left,
  mid,
  right,
  hidePaddingOnMobile,
}: Props) {
  return (
    <div className={cn(styles.bordered, "flex", className)}>
      <div
        className={cn(
          "w-4 xs:w-10 flex-shrink-0",
          hidePaddingOnMobile && "hidden xs:block"
        )}
      >
        {left}
      </div>
      <div
        className={cn(
          styles.bordered,
          styles.vertLines,
          "flex-1 overflow-hidden",
          hidePaddingOnMobile && styles.mobile
        )}
      >
        {mid}
      </div>
      <div
        className={cn(
          "w-20 flex-shrink-0",
          hidePaddingOnMobile && "hidden xs:block"
        )}
      >
        {right}
      </div>
    </div>
  );
}
