import React from "react";
import { Vector3, Object3D } from "three";
import { Howl } from "howler";

import windSrc from "./wind.mp3";
import { useAudioContext } from "../../../../lib/audio";
import { useFrame } from "@react-three/fiber";

const pos = new Vector3();
const volumeUpdateFreq = 250;

export function useCamAudio({
  camRef,
}: {
  camRef: React.MutableRefObject<Object3D | null>;
}) {
  const { muted } = useAudioContext();

  const howl = React.useMemo(
    () =>
      new Howl({
        src: windSrc,
        loop: true,
        volume: 0,
      }),
    []
  );

  React.useEffect(() => () => void howl.stop(), [howl]);

  const lastPosRef = React.useRef<Vector3 | null>(null);
  const speedRef = React.useRef(0);
  useFrame((_, delta) => {
    const cam = camRef.current;
    if (!cam) return;

    if (!lastPosRef.current)
      lastPosRef.current = cam.getWorldPosition(new Vector3());

    cam.getWorldPosition(pos);

    const dist = lastPosRef.current.distanceTo(pos) / delta;
    const speed = Math.min(1, dist / 500);
    speedRef.current = Math.max(
      0,
      speedRef.current + (speed - speedRef.current) / 4
    );
    if (isNaN(speedRef.current)) speedRef.current = 0;

    lastPosRef.current.copy(pos);
  });

  React.useEffect(() => {
    let stopTimeout: NodeJS.Timeout | null = null;

    const fadeTo = (volume: number, duration = 1000) => {
      if (stopTimeout) clearTimeout(stopTimeout);
      howl.fade(howl.volume(), volume, duration);
      if (volume === 0) stopTimeout = setTimeout(() => howl.stop(), 1050);
    };

    if (muted) {
      fadeTo(0);
      return;
    }

    let mounted = true;
    let prevActive = false;

    const interval = setInterval(() => {
      if (!mounted) return;

      const volume = Math.pow(speedRef.current, 1.15);
      const active = volume > 0.012;

      if (active && !prevActive) howl.play();

      if (active) fadeTo(volume, volumeUpdateFreq);
      else if (prevActive) fadeTo(0);

      prevActive = active;
    }, volumeUpdateFreq);

    return () => {
      mounted = false;
      clearInterval(interval);
    };
  }, [muted, howl]);
}
