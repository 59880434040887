import React from "react";
import shuffle from "lodash/shuffle";

import { useCardVisibility } from "../../../ui/card";
import { useCurrentHotspot } from "./hotspot";

type HighlightHintData = {
  setActive(value: boolean): void;
  enabledRef: React.RefObject<boolean>;
};
type HighlightHinterContext = {
  registerObject(data: HighlightHintData): () => void;
};
type HighlightHintRegistration = {
  data: HighlightHintData;
  mounted: boolean;
};

const Context = React.createContext<HighlightHinterContext | null>(null);

export function useHighlightHint(enabled = true): boolean {
  const ctx = React.useContext(Context);
  const [active, setActive] = React.useState(false);
  const enabledRef = React.useRef(enabled);

  React.useEffect(() => ctx?.registerObject({ setActive, enabledRef }), [ctx]);

  React.useEffect(() => {
    enabledRef.current = enabled;
  }, [enabled]);

  return active;
}

function useWindowFocused() {
  const [focused, setFocused] = React.useState(true);
  React.useEffect(() => {
    const handleBlur = () => setFocused(false);
    const handleFocus = () => setFocused(true);
    window.addEventListener("focus", handleFocus);
    window.addEventListener("blur", handleBlur);
    return () => {
      window.removeEventListener("focus", handleFocus);
      window.removeEventListener("blur", handleBlur);
    };
  }, []);
  return focused;
}

function useMouseJustMoved(): boolean {
  const [mouseMoved, setMouseMoved] = React.useState(true);
  React.useEffect(() => {
    let timeout: NodeJS.Timeout | null = null;

    const handleMouseMove = () => {
      if (timeout) clearTimeout(timeout);
      setMouseMoved(true);
      timeout = setTimeout(() => setMouseMoved(false), 6000);
    };

    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      if (timeout) clearTimeout(timeout);
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);
  return mouseMoved;
}

export const HighlightHinterProvider: React.FC = ({ children }) => {
  const dataRef = React.useRef<HighlightHintRegistration[]>([]);
  const currentHotspot = useCurrentHotspot();

  const ctx = React.useMemo<HighlightHinterContext>(
    () => ({
      registerObject(data) {
        dataRef.current.push({ data, mounted: true });

        return () => {
          const registration = dataRef.current.find((reg) => reg.data === data);
          if (!registration) return;
          dataRef.current = dataRef.current.filter(
            (reg) => reg !== registration
          );
          registration.mounted = false;
        };
      },
    }),
    []
  );

  const windowFocused = useWindowFocused();
  const mouseJustMoved = useMouseJustMoved();
  const cardIsVisible = useCardVisibility();
  const disabled = !windowFocused || mouseJustMoved || cardIsVisible;

  React.useEffect(() => {
    if (disabled) return;

    let timeouts: NodeJS.Timeout[] = [];
    const setTimeout_ = (cb: () => void, duration: number) => {
      const timeout = setTimeout(() => {
        cb();
        timeouts = timeouts.filter((to) => to !== timeout);
      }, duration);
      timeouts.push(timeout);
    };

    let cleanup: (() => void) | null = null;

    const highlightNodes = () => {
      const registrations = shuffle(
        dataRef.current.filter((reg) => reg.data.enabledRef.current)
      );
      if (!registrations.length) return;

      let i = 0;

      cleanup = () => {
        registrations.forEach((reg) => {
          if (reg.mounted) reg.data.setActive(false);
        });
      };

      const step = () => {
        let reg: HighlightHintRegistration | null = null;

        do {
          if (i >= registrations.length) return;
          reg = registrations[i++];
        } while (!reg?.mounted);

        reg.data.setActive(true);
        setTimeout_(
          () => void (reg!.mounted && reg!.data.setActive(false)),
          1000
        );

        if (i < registrations.length) setTimeout_(step, 100);
      };

      step();
    };

    highlightNodes();
    const interval = setInterval(highlightNodes, 12000);

    return () => {
      clearInterval(interval);
      if (cleanup) cleanup();
      timeouts.forEach((timeout) => clearTimeout(timeout));
    };
  }, [disabled, currentHotspot]);

  return <Context.Provider value={ctx}>{children}</Context.Provider>;
};

const HighlightableNodeContext = React.createContext(false);

export const HighlightableNode: React.FC<{ enabled: boolean }> = ({
  children,
  enabled,
}) => (
  <HighlightableNodeContext.Provider value={useHighlightHint(enabled)}>
    {children}
  </HighlightableNodeContext.Provider>
);

export function useIsHighlighted() {
  return React.useContext(HighlightableNodeContext);
}
