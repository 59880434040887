import React from "react";

import {
  PreloadProvider,
  usePreloadResult,
  PreloadResult,
  PreloadAsset,
} from "./context";

export const Preloader: React.FC<{
  assets: PreloadAsset<any>[];
  setProgress?: (value: number) => void;
}> = ({ children, assets, setProgress }) => {
  const [result, setResult] = React.useState<PreloadResult | null>(null);

  React.useEffect(() => {
    let mounted = true;
    const totalSize = assets
      .map((asset) => asset.size)
      .reduce((prev, curr) => prev + curr);
    const progressValues = assets.map(() => 0);

    function updateProgress() {
      const progress = Math.min(
        progressValues.reduce((prev, curr) => prev + curr) / totalSize,
        1
      );
      if (setProgress) setProgress(progress);
    }

    Promise.all(
      assets.map(({ src, loader }, i) =>
        loader(src, (progress) => {
          if (!mounted) return;
          progressValues[i] = Math.min(progress, assets[i].size);
          updateProgress();
        }).then((data) => {
          if (!mounted) return;
          progressValues[i] = assets[i].size;
          updateProgress();
          return data;
        })
      )
    ).then((data) => {
      if (!mounted) return;
      const result: PreloadResult = {};
      data.forEach((data, i) => {
        result[assets[i].src] = data;
      });
      if (setProgress) setProgress(1);
      setResult(result);
    });

    return () => {
      mounted = false;
    };
  }, [assets, setProgress]);

  return <PreloadProvider value={result}>{children}</PreloadProvider>;
};

export function usePreloadingFinished(): boolean {
  return !!usePreloadResult();
}

export function usePreloadedAsset<T>(src: string): T | null {
  const result = usePreloadResult();
  return (result && result[src]) || null;
}

export const PreloadSwitch: React.FC = ({ children }) =>
  usePreloadingFinished() ? <>{children}</> : null;
