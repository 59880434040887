import React from "react";
import { Vector2, Object3D } from "three";
import { useFrame } from "@react-three/fiber";

type RotationState = {
  target: Vector2;
  current: Vector2;
};

const tmp = new Vector2();

export const Gimbal: React.FC<{ disabled?: boolean }> = ({
  children,
  disabled,
}) => {
  const ref = React.useRef<Object3D>(null);
  const rotationState = React.useRef<RotationState | null>(null);

  React.useEffect(() => {
    if ("ontouchstart" in window) return;
    if (disabled) {
      rotationState.current?.target.set(0, 0);
      return;
    }

    function handleMouseMove({ clientX, clientY }: MouseEvent) {
      if (!rotationState.current) return;

      const { target } = rotationState.current;

      const { innerWidth, innerHeight } = window;
      const max = innerWidth > innerHeight ? innerWidth : innerHeight;
      target.set(clientX / max, clientY / max);
      target.addScalar(-0.5);
      target.multiplyScalar(0.3);
      target.y *= 0.35;
    }
    function handleMouseOut() {
      if (rotationState.current) rotationState.current.target.set(0, 0);
    }

    window.addEventListener("mouseout", handleMouseOut);
    window.addEventListener("mousemove", handleMouseMove);
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseout", handleMouseOut);
    };
  }, [disabled]);

  useFrame(() => {
    if (!rotationState.current)
      rotationState.current = { target: new Vector2(), current: new Vector2() };
    const { target, current } = rotationState.current;
    current.add(tmp.copy(target).sub(current).multiplyScalar(0.01));
    ref.current?.rotation.set(0, -current.x, 0);
  });

  return <group ref={ref}>{children}</group>;
};
