import React from "react";
import { Link } from "gatsby";

import { useAudioContext } from "../../../lib/audio";
import { MuteIcon } from "./mute-icon";
import { useIsReady } from "../../ready-state";

import * as styles from "./utility.module.css";

const LabeledButton: React.FC<{ label: string }> = ({ children, label }) => (
  <div className={styles.labeled}>
    {children}
    <div className={styles.label}>{label}</div>
  </div>
);

function MuteUI() {
  const { muted, setMuted } = useAudioContext();

  return (
    <LabeledButton label={`Ton ${muted ? "an" : "aus"}`}>
      <button
        className="px-4 py-2 w-8 box-content transition opacity-50 active:opacity-100 pointer-events-auto"
        onClick={() => setMuted(!muted)}
      >
        <MuteIcon muted={muted} />
      </button>
    </LabeledButton>
  );
}

function HomeButton() {
  const isReady = useIsReady();

  return (
    <LabeledButton label="Start">
      <Link
        to="/start/"
        className={`block px-4 py-2 w-8 box-content transition active:opacity-100 ${
          isReady ? "pointer-events-auto opacity-50" : "opacity-0"
        }`}
        draggable={false}
      >
        <svg className="w-full h-auto" viewBox="0 0 20 20" fill="currentColor">
          <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
        </svg>
      </Link>
    </LabeledButton>
  );
}

function CatalogueButton() {
  return (
    <LabeledButton label="Katalog">
      <Link
        to="/katalog/"
        className="block px-3.5 py-2 w-8 box-content transition active:opacity-100 pointer-events-auto opacity-50"
        draggable={false}
      >
        <svg className="w-full h-auto" viewBox="0 0 20 20" fill="currentColor">
          <path d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z" />
          <path
            fillRule="evenodd"
            d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm3 4a1 1 0 000 2h.01a1 1 0 100-2H7zm3 0a1 1 0 000 2h3a1 1 0 100-2h-3zm-3 4a1 1 0 100 2h.01a1 1 0 100-2H7zm3 0a1 1 0 100 2h3a1 1 0 100-2h-3z"
            clipRule="evenodd"
          />
        </svg>
      </Link>
    </LabeledButton>
  );
}

export function UtilityUI() {
  return (
    <div className="py-2">
      <HomeButton />
      <CatalogueButton />
      <MuteUI />
    </div>
  );
}
