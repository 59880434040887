import React from "react";
import { Link } from "gatsby";
import cn from "classnames";

import { useIsReady } from "../ready-state";

import * as styles from "./ui.module.css";

export function BackLink({ to }: { to: string | null }) {
  const isReady = useIsReady();
  const active = isReady && !!to;

  const svg = (
    <svg viewBox="0 0 40 64" fill="currentColor" className="text-text w-5">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.0213 31.8619L40 54.8406L31.4894 63.3513L0 31.8619L31.4894 0.372559L40 8.8832L17.0213 31.8619Z"
      />
    </svg>
  );

  const paddingClass = "p-4";

  return (
    <div className="absolute left-0 bottom-0 flex justify-center">
      <div
        className={cn(
          "relative font-bold text-2xl uppercase tracking-wider",
          active ? "pointer-events-auto" : paddingClass
        )}
      >
        <div className={cn(styles.anim, !active && styles.offset)}>
          {to ? (
            <Link
              to={to}
              className={`block transition opacity-50 active:opacity-100 ${paddingClass}`}
            >
              {svg}
            </Link>
          ) : (
            svg
          )}
        </div>
      </div>
    </div>
  );
}
