import React from "react";

import { useCurrentHotspot, HotspotNodeObject } from "../three";
import { AsideUI } from "./aside";
import { CardUI } from "./card";

function getHotspotPath({ name }: HotspotNodeObject) {
  return name ? `/${name}/` : "/";
}

// function usePrevPath(): string | null {
//   const hotspot = useCurrentHotspot();

//   const [prevHotspot, setPrevHotspot] =
//     React.useState<HotspotNodeObject | null>(null);
//   React.useEffect(() => () => setPrevHotspot(hotspot), [hotspot]);

//   return React.useMemo(() => {
//     if (prevHotspot && prevHotspot.level < hotspot!.level)
//       return getHotspotPath(prevHotspot);
//     if (hotspot?.parent) return getHotspotPath(hotspot.parent);
//     return null;
//   }, [hotspot, prevHotspot]);
// }

function usePrevPath(): string | null {
  const hotspot = useCurrentHotspot();
  return React.useMemo(
    () => (hotspot?.parent ? getHotspotPath(hotspot.parent) : null),
    [hotspot]
  );
}

export const UI: React.FC = ({ children }) => {
  const prevPath = usePrevPath();

  return (
    <>
      <AsideUI prevPath={prevPath} />
      <CardUI prevPath={prevPath}>{children}</CardUI>
    </>
  );
};
