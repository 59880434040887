import React from "react";
import { useFrame } from "@react-three/fiber";

export function QualityChecker({ onQualityGood }: { onQualityGood(): void }) {
  const timeoutRef = React.useRef<NodeJS.Timeout | null>(null);
  const resetCheck = React.useCallback(() => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(onQualityGood, 1500);
  }, [onQualityGood]);
  React.useEffect(() => resetCheck(), [resetCheck]);

  useFrame((_, delta) => {
    if (delta > 0.05) resetCheck();
  });

  return null;
}
