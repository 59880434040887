import React from "react";

import { HighlightableNode } from "../highlight-hinter";
import { useHotspotScope } from "../../../lib/hotspots";

export const Highlight: React.FC = ({ children }) => {
  const scope = useHotspotScope();
  return (
    <HighlightableNode enabled={!!scope?.interactive}>
      {children}
    </HighlightableNode>
  );
};
