import React from "react";
import { Color } from "three";
import { navigate } from "gatsby";

import {
  Button,
  ButtonProps,
  SphereButton,
  ShadedButton,
  ShadedButtonProps,
} from "./lib/button";
import { usePageContext } from "../../gatsby/context";
import { useCursorStyle } from "./lib/cursor-style";

function useNavigateHandler(to: string): () => void {
  const [loadingPath, setLoadingPath] = React.useState<string | null>(null);
  const [loading, setLoading] = React.useState(false);
  const { pagePath } = usePageContext();

  React.useEffect(() => {
    if (!loadingPath) {
      setLoading(false);
      return;
    }

    const timeout = setTimeout(() => setLoading(true), 100);
    return () => clearTimeout(timeout);
  }, [loadingPath]);

  useCursorStyle((loading && "wait") || null, true);

  React.useEffect(() => {
    if (loadingPath === pagePath) setLoadingPath(null);
  }, [loadingPath, pagePath]);

  return () => {
    setLoadingPath(to);
    navigate(to);
  };
}

type LinkProps = Omit<ButtonProps, "onClick"> & { to: string };

export const Link: React.FC<LinkProps> = ({ to, ...props }) => (
  <Button {...props} onClick={useNavigateHandler(to)} />
);

export function SphereLink({ to, ...props }: LinkProps) {
  return (
    <group scale={[5, 5, 5]}>
      <SphereButton {...props} onClick={useNavigateHandler(to)} />
    </group>
  );
}

const color = new Color(0xfe9b96).convertSRGBToLinear();

export function ShadedLink({
  to,
  ...props
}: Omit<ShadedButtonProps, "onClick"> & {
  to: string;
  label?: string;
}) {
  return (
    <group scale={[5, 5, 5]}>
      <ShadedButton
        {...props}
        color={color}
        invisible={props.invisible}
        onClick={useNavigateHandler(to)}
      />
    </group>
  );
}
