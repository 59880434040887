import type { ObjectMap } from "@react-three/fiber";
import {
  GLTFLoader,
  GLTF as ThreeGLTF,
} from "three/examples/jsm/loaders/GLTFLoader";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";

export type GLTF = ThreeGLTF & ObjectMap;

function convertGLTF(gltf: ThreeGLTF): GLTF {
  const data: ObjectMap = { nodes: {}, materials: {} };
  gltf.scene.traverse((obj: any) => {
    if (obj.name) data.nodes[obj.name] = obj;
    if (obj.material && !data.materials[obj.material.name])
      data.materials[obj.material.name] = obj.material;
  });
  return Object.assign(gltf, data);
}

function addDraco(loader: GLTFLoader) {
  const dracoLoader = new DRACOLoader();
  dracoLoader.setDecoderPath("https://www.gstatic.com/draco/v1/decoders/");
  loader.setDRACOLoader(dracoLoader);
}

export async function loadGLTF(
  src: string,
  onProgress: (progress: number) => void
) {
  const loader = new GLTFLoader();
  addDraco(loader);

  const gltf = await loader.loadAsync(src, ({ loaded }) => {
    onProgress(loaded);
  });

  return convertGLTF(gltf);
}
