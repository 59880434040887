import React from "react";

import { useAudioClip } from "../../../../lib/audio";
import { useCardVisibility } from "..";

import onSrc from "./on.mp3";
import offSrc from "./off.mp3";
import { useIsReady } from "../../../ready-state";

export function useCardAudio() {
  const ready = useIsReady();
  const on = useAudioClip({ src: onSrc });
  const off = useAudioClip({ src: offSrc });
  const visible = useCardVisibility();
  const [handledValue, setHandledValue] = React.useState(visible);

  React.useEffect(() => {
    if (!ready || visible === handledValue) return;
    if (visible) on.play();
    else off.play();
    setHandledValue(visible);
  }, [ready, visible, handledValue, on, off]);
}
