import React from "react";
import {
  Vector3,
  BufferGeometry,
  Mesh as ThreeMesh,
  MeshStandardMaterial,
} from "three";

export type StandardMesh = ThreeMesh<BufferGeometry, MeshStandardMaterial>;

export function useVector3(x: number, y: number, z: number) {
  return React.useMemo(() => new Vector3(x, y, z), [x, y, z]);
}
