import React from "react";
import cn from "classnames";
import { Transition } from "react-transition-group";

import { ProgressBar, ProgressBarProps } from "./progress-bar";
import { LandingLoadingScreen } from "./landing";
import { usePageContext } from "../../../gatsby/context";
import { useIsReady } from "../../ready-state";

export function LoadingScreen(props: ProgressBarProps) {
  const isReady = useIsReady();
  const { pagePath } = usePageContext();
  const isLandingPage = pagePath === "/";

  return (
    <div
      className={cn(
        "fixed inset-0 z-10 overflow-hidden",
        isReady && "pointer-events-none"
      )}
    >
      <Transition in={isLandingPage} timeout={2000}>
        {(state) =>
          state !== "exited" ? (
            <LandingLoadingScreen
              {...props}
              active={state == "entering" || state == "entered"}
            />
          ) : (
            <ProgressBar {...props} />
          )
        }
      </Transition>
    </div>
  );
}
