import React from "react";
import { Color, Vector3Tuple } from "three";
import { useSpring, animated } from "@react-spring/three";

import { useCurrentHotspot } from "../lib/hotspots";

export function Lighting() {
  const hotspot = useCurrentHotspot();

  const { position } = useSpring({
    position: (hotspot?.name == ""
      ? [90, 200, 0]
      : [50, 150, 220]) as Vector3Tuple,
    config: { tension: 19, precision: 0.0005 },
  });

  return (
    <>
      <hemisphereLight
        color={new Color(0xd4eeff)}
        groundColor={new Color(0xe3a5a1)}
        intensity={0.58}
      />
      <animated.directionalLight
        color={new Color(0xfffef7)}
        castShadow
        position={position}
        intensity={0.65}
        shadow-mapSize-width={1536}
        shadow-mapSize-height={1536}
        shadow-camera-near={1}
        shadow-camera-far={1000}
        shadow-camera-left={-50}
        shadow-camera-right={50}
        shadow-camera-top={50}
        shadow-camera-bottom={-50}
        shadow-bias={-0.0004}
      />
    </>
  );
}
