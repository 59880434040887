import React from "react";
import { AnimationMixer, LoopOnce } from "three";
import { useFrame } from "@react-three/fiber";
import type { GLTF } from "three/examples/jsm/loaders/GLTFLoader";

enum TransitionState {
  InProgress = "IN_PROGRESS",
  Start = "START",
  End = "END",
}

export function useGLTFTransition(
  gltf: GLTF,
  clipNameInput: string | string[],
  seekEnd = true,
  startAtEnd = false
) {
  const [state, setState] = React.useState<TransitionState>(
    startAtEnd ? TransitionState.End : TransitionState.Start
  );

  const currentTimeRef = React.useRef(0);

  const [mixer, actions] = React.useMemo(() => {
    const mixer = new AnimationMixer(gltf.scene);

    return [
      mixer,
      (Array.isArray(clipNameInput) ? clipNameInput : [clipNameInput]).map(
        (name) => {
          const clip = gltf.animations.find((clip) => clip.name === name)!;
          currentTimeRef.current = startAtEnd ? clip.duration : 0;
          const action = mixer.clipAction(clip);
          action.time = currentTimeRef.current;
          action.loop = LoopOnce;
          action.clampWhenFinished = true;
          action.play();
          mixer.update(0);
          return action;
        }
      ),
    ];
  }, [gltf, startAtEnd, clipNameInput]);

  useFrame((_, delta) => {
    if (state === TransitionState.InProgress) mixer.update(delta);
  });

  React.useEffect(
    () =>
      actions.forEach((action) => {
        action.setEffectiveTimeScale(seekEnd ? 1 : -1);
      }),
    [state, seekEnd, actions]
  );

  React.useEffect(() => {
    if (state !== TransitionState.InProgress) return;

    function handleActionFinished() {
      setState(seekEnd ? TransitionState.End : TransitionState.Start);
    }

    mixer.addEventListener("finished", handleActionFinished as any);

    actions.forEach((action) => {
      action.reset();
      action.time = currentTimeRef.current;
      action.play();
    });

    return () => {
      mixer.removeEventListener("finished", handleActionFinished as any);
      currentTimeRef.current = actions[0].time;
    };
  }, [state, seekEnd, actions, mixer]);

  React.useEffect(() => {
    if (
      state === TransitionState.InProgress ||
      (seekEnd && state === TransitionState.End) ||
      (!seekEnd && state === TransitionState.Start)
    )
      return;

    setState(TransitionState.InProgress);
  }, [state, seekEnd]);

  return state === TransitionState.InProgress;
}
