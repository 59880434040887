import React from "react";
import { Vector3 } from "three";

import { useVector3 } from "../../../lib/util";

import { Hotspot } from "../hotspot";
import {
  EnableOnActiveOrParent,
  ParentModifier,
  SelectionModifier,
} from "../../../lib/hotspots";
import { HighlightHinterProvider } from "../highlight-hinter";
import { HotspotButton as Button } from "../button";
import { HotspotRootObject as RootObject } from "../root-object";
import { HotspotTransition } from "../transition";

import { Highlight } from "./highlight";

import { Room as Vestibuel } from "./rooms/vestibuel";
import { Room as Kuppelsaal } from "./rooms/kuppelsaal";
import { Room as Gartensaal } from "./rooms/gartensaal-ea";
import { Room as Schlafzimmer } from "./rooms/schlafzimmer-ea";
import { Room as Gesellschaftsraum } from "./rooms/gesellschaftsraum-so";
import { HotspotMesh } from "../mesh";
import { ButtonSystem } from "../button-system";

const frontHotspots = ["gartenkunstmuseum", "vorplatz", "naturkundemuseum"];

export function HotspotComposition() {
  return (
    <HighlightHinterProvider>
      <Hotspot
        name=""
        focusPoint={useVector3(0, 0, 0)}
        cam={{
          orbitLat: -Math.PI / 2,
          orbitLong: 0,
          zoom: 150,
        }}
      >
        <Hotspot
          name="katalog"
          focusPoint={useVector3(0, 20, -20)}
          cam={{
            orbitLat: -Math.PI * 0.5,
            orbitLong: 0,
            zoom: 200,
          }}
        />
        <Hotspot
          name="uebersicht"
          focusPoint={useVector3(0, 20, -20)}
          cam={{
            orbitLat: -Math.PI * 0.5,
            orbitLong: 0,
            zoom: 200,
          }}
        >
          <Button size={0.667} shaded />
        </Hotspot>
        <Hotspot
          name="start"
          focusPoint={useVector3(0, 6, 0)}
          cam={{
            orbitLat: -Math.PI * 0.1,
            orbitLong: 0,
            zoom: 100,
          }}
        >
          <Hotspot
            name="vorplatz"
            cam={{
              orbitLat: -Math.PI * 0.1,
              orbitLong: 0,
              zoom: 50,
            }}
          >
            <Hotspot
              name="lichthoefe"
              cam={{
                orbitLat: -Math.PI * 0.25,
                orbitLong: Math.PI * 0.15,
                zoom: 20,
              }}
            >
              <SelectionModifier
                names={React.useMemo(
                  () => [
                    "start",
                    "vorplatz",
                    "englischer-garten",
                    "franzoesischer-garten",
                    "spiegelweiher",
                  ],
                  []
                )}
                affect="interactivity"
              >
                <Highlight>
                  <HotspotMesh />
                  <ButtonSystem size={0.5} />

                  <EnableOnActiveOrParent>
                    <Hotspot
                      name="badezimmer"
                      focusPoint={useVector3(-5.16, 1.39, -7.75)}
                      cam={{
                        orbitLat: -Math.PI * 0.3,
                        orbitLong: 0,
                        zoom: 12,
                      }}
                    >
                      <Button shaded size={0.15} />
                    </Hotspot>
                  </EnableOnActiveOrParent>
                </Highlight>
              </SelectionModifier>
            </Hotspot>

            <Hotspot
              name="schlossweiher"
              cam={{
                orbitLat: -Math.PI * 0.2,
                orbitLong: 0,
                zoom: 40,
              }}
            >
              <ParentModifier affect="interactivity">
                <Highlight>
                  <RootObject transparent isButton />
                </Highlight>
              </ParentModifier>
            </Hotspot>

            <EnableOnActiveOrParent>
              <Button size={0.667} shaded />

              <Vestibuel enabledIn={frontHotspots} />

              <Hotspot
                name="schwan"
                cam={{
                  orbitLat: -Math.PI * 0.1,
                  orbitLong: -0.05,
                  zoom: 15,
                }}
              >
                <Highlight>
                  <Button size={0.2} />
                  <RootObject />
                </Highlight>
              </Hotspot>

              <Hotspot
                name="uhr"
                cam={{
                  orbitLat: -Math.PI / 14,
                  orbitLong: 0,
                  zoom: 8,
                }}
              >
                <SelectionModifier
                  names={frontHotspots}
                  affect="interactivity"
                  additive
                >
                  <Highlight>
                    <Button size={0.15} />
                    <RootObject />
                  </Highlight>
                </SelectionModifier>
              </Hotspot>

              <Hotspot
                name="wappen-und-kurhut"
                objectName="wappen"
                cam={{
                  orbitLat: -Math.PI / 14,
                  orbitLong: 0,
                  zoom: 8,
                }}
              >
                <SelectionModifier
                  names={frontHotspots}
                  affect="interactivity"
                  additive
                >
                  <Highlight>
                    <Button size={0.15} />
                    <HotspotMesh />
                  </Highlight>
                </SelectionModifier>
              </Hotspot>

              <Hotspot
                name="insel-im-schlossweiher"
                objectName="weideninsel"
                cam={{
                  orbitLat: -Math.PI * 0.08,
                  orbitLong: -Math.PI * 0.2,
                  zoom: 15,
                }}
              >
                <SelectionModifier
                  names={React.useMemo(() => ["start", ...frontHotspots], [])}
                  affect="interactivity"
                  additive
                >
                  <Highlight>
                    <Button size={0.4} />
                    <RootObject />
                  </Highlight>
                </SelectionModifier>
              </Hotspot>

              <Hotspot
                name="loewen"
                cam={{
                  orbitLat: -Math.PI / 10,
                  orbitLong: 0,
                  zoom: 12,
                }}
              >
                <SelectionModifier
                  names={frontHotspots}
                  affect="interactivity"
                  additive
                >
                  <Highlight>
                    <HotspotMesh />
                    <ButtonSystem size={0.2} />
                  </Highlight>
                </SelectionModifier>
              </Hotspot>
            </EnableOnActiveOrParent>
          </Hotspot>

          <Hotspot
            name="schlosspark"
            cam={{
              orbitLat: -0.524,
              orbitLong: -2.827,
              zoom: 80,
            }}
          >
            <Button size={0.667} position={new Vector3(0, 2.8, 0)} shaded />

            <Hotspot
              name="spiegelweiher"
              cam={{
                orbitLat: -0.3,
                orbitLong: -Math.PI,
                zoom: 40,
              }}
            >
              <Highlight>
                <ParentModifier affect="interactivity">
                  <RootObject transparent isButton />
                </ParentModifier>
              </Highlight>
            </Hotspot>

            <Hotspot
              name="orangerie"
              cam={{
                orbitLat: -0.12 * Math.PI,
                orbitLong: -0.15 * Math.PI,
                zoom: 40,
              }}
            >
              <Highlight>
                <RootObject transparent isButton />
              </Highlight>
            </Hotspot>

            <Kuppelsaal />

            <EnableOnActiveOrParent>
              <Hotspot
                name="jagdachse"
                cam={{
                  orbitLat: -0.15 * Math.PI,
                  orbitLong: -Math.PI,
                  zoom: 30,
                }}
              >
                <Highlight>
                  <ParentModifier affect="interactivity">
                    <RootObject transparent isButton />
                  </ParentModifier>
                </Highlight>
              </Hotspot>

              <Hotspot
                name="kanadagans"
                cam={{
                  orbitLat: -0.3,
                  orbitLong: -Math.PI,
                  zoom: 12,
                }}
              >
                <Highlight>
                  <Button size={0.2} />
                  <RootObject />
                </Highlight>
              </Hotspot>

              <Hotspot
                name="tierischer-wecker"
                focusPointObjectName="tierischer_wecker"
                cam={{
                  orbitLat: -0.32,
                  orbitLong: 0.88 * Math.PI,
                  zoom: 34,
                }}
              >
                <Highlight>
                  <Button size={0.3} shaded />
                </Highlight>
              </Hotspot>

              <Hotspot
                name="karpfen"
                cam={{
                  orbitLat: -0.24,
                  orbitLong: -1.1 * Math.PI,
                  zoom: 10,
                }}
              >
                <Highlight>
                  <Button size={0.2} />
                  <RootObject />
                </Highlight>
              </Hotspot>

              <Hotspot
                name="eichenbock"
                cam={{
                  orbitLat: -0.9,
                  orbitLong: -0.32 * Math.PI,
                  zoom: 10,
                }}
              >
                <Highlight>
                  <Button size={0.2} />
                  <RootObject />
                </Highlight>
              </Hotspot>

              <Hotspot
                name="linde"
                cam={{
                  orbitLat: -0.3,
                  orbitLong: -0.9 * Math.PI,
                  zoom: 15,
                }}
              >
                <Highlight>
                  <Button size={0.3} />
                  <RootObject />
                </Highlight>
              </Hotspot>

              <Hotspot
                name="nilgans"
                cam={{
                  orbitLat: -0.2,
                  orbitLong: 0.9 * Math.PI,
                  zoom: 10,
                }}
              >
                <Highlight>
                  <Button size={0.2} />
                  <RootObject />
                </Highlight>
              </Hotspot>

              <Hotspot
                name="atalante-meleager"
                cam={{
                  orbitLat: -0.2,
                  orbitLong: -Math.PI,
                  zoom: 10,
                }}
              >
                <Highlight>
                  <RootObject isButton />
                </Highlight>
              </Hotspot>

              <Hotspot
                name="flora"
                cam={{
                  orbitLat: -0.1,
                  orbitLong: -Math.PI,
                  zoom: 10,
                }}
              >
                <Highlight>
                  <RootObject isButton />
                </Highlight>
              </Hotspot>

              <Hotspot
                name="pan"
                cam={{
                  orbitLat: -0.1,
                  orbitLong: -Math.PI,
                  zoom: 10,
                }}
              >
                <Highlight>
                  <RootObject isButton />
                </Highlight>
              </Hotspot>

              <Hotspot
                name="pomona"
                cam={{
                  orbitLat: -0.1,
                  orbitLong: -Math.PI,
                  zoom: 10,
                }}
              >
                <Highlight>
                  <RootObject isButton />
                </Highlight>
              </Hotspot>

              <Hotspot
                name="bacchus"
                objectName="dyonisos"
                cam={{
                  orbitLat: -0.3,
                  orbitLong: -Math.PI,
                  zoom: 10,
                }}
              >
                <Highlight>
                  <RootObject isButton />
                </Highlight>
              </Hotspot>

              <Hotspot
                name="hirschkaefer"
                cam={{
                  orbitLat: -0.8,
                  orbitLong: -0.76 * Math.PI,
                  zoom: 10,
                }}
              >
                <Highlight>
                  <Button size={0.2} />
                  <RootObject />
                </Highlight>
              </Hotspot>

              <Hotspot
                name="eiche"
                cam={{
                  orbitLat: -0.3,
                  orbitLong: -0.38 * Math.PI,
                  zoom: 20,
                }}
              >
                <Highlight>
                  <Button size={0.4} />
                  <RootObject />
                </Highlight>
              </Hotspot>

              <Hotspot
                name="diana"
                cam={{
                  orbitLat: -0.2,
                  orbitLong: -Math.PI,
                  zoom: 8,
                }}
              >
                <ParentModifier affect="interactivity">
                  <Highlight>
                    <Button size={0.2} />
                    <HotspotMesh />
                  </Highlight>
                </ParentModifier>
              </Hotspot>

              <Hotspot
                name="laterne"
                objectName="dachgeschoss"
                cam={{
                  orbitLat: -0.2,
                  orbitLong: -Math.PI,
                  zoom: 8,
                }}
              >
                <ParentModifier affect="interactivity">
                  <SelectionModifier
                    names={React.useMemo(
                      () => ["start", "englischer-garten", ...frontHotspots],
                      []
                    )}
                    affect="interactivity"
                    additive
                  >
                    <Highlight>
                      <Button size={0.33} />
                      <RootObject disableGlow />

                      <HotspotTransition
                        objectName="dachgeschoss_l"
                        rotation={useVector3(-Math.PI / 2, 0, 0)}
                      />

                      <HotspotTransition
                        objectName="dachgeschoss_r"
                        rotation={useVector3(-Math.PI / 2, 0, 0)}
                      />
                    </Highlight>
                  </SelectionModifier>
                </ParentModifier>
              </Hotspot>

              <Gartensaal />
            </EnableOnActiveOrParent>
          </Hotspot>

          <Hotspot
            name="englischer-garten"
            cam={{
              orbitLat: -0.11 * Math.PI,
              orbitLong: 0.33 * Math.PI,
              zoom: 60,
            }}
          >
            <Button size={0.667} shaded />

            <EnableOnActiveOrParent>
              <Hotspot
                name="eisvogel"
                cam={{
                  orbitLat: -Math.PI * 0.08,
                  orbitLong: Math.PI * 0.38,
                  zoom: 10,
                }}
              >
                <SelectionModifier
                  names="naturkundemuseum"
                  affect="interactivity"
                  additive
                >
                  <Highlight>
                    <Button size={0.15} />
                    <RootObject />
                  </Highlight>
                </SelectionModifier>
              </Hotspot>

              <Hotspot
                name="mammutbaum"
                cam={{
                  orbitLat: -Math.PI * 0.12,
                  orbitLong: Math.PI * 0.28,
                  zoom: 20,
                }}
              >
                <SelectionModifier
                  names="naturkundemuseum"
                  affect="interactivity"
                  additive
                >
                  <Highlight>
                    <Button size={0.3} />
                    <RootObject />
                  </Highlight>
                </SelectionModifier>
              </Hotspot>

              <Hotspot
                name="judasblattbaum"
                cam={{
                  orbitLat: -Math.PI * 0.08,
                  orbitLong: Math.PI * 0.38,
                  zoom: 16,
                }}
              >
                <Highlight>
                  <Button size={0.3} />
                  <RootObject />
                </Highlight>
              </Hotspot>

              <Hotspot
                name="sumpfzypresse"
                cam={{
                  orbitLat: -Math.PI * 0.08,
                  orbitLong: Math.PI * 0.28,
                  zoom: 16,
                }}
              >
                <Highlight>
                  <Button size={0.25} />
                  <RootObject />
                </Highlight>
              </Hotspot>

              <Hotspot
                name="milchorangenbaum"
                cam={{
                  orbitLat: -Math.PI * 0.08,
                  orbitLong: Math.PI * 0.2,
                  zoom: 16,
                }}
              >
                <Highlight>
                  <Button size={0.25} />
                  <RootObject />
                </Highlight>
              </Hotspot>
            </EnableOnActiveOrParent>
          </Hotspot>

          <Hotspot
            name="franzoesischer-garten"
            cam={{
              orbitLat: -0.15 * Math.PI,
              orbitLong: -0.67 * Math.PI,
              zoom: 42,
            }}
          >
            <Button size={0.667} shaded />

            <EnableOnActiveOrParent>
              <Hotspot
                name="kaskade"
                cam={{
                  orbitLat: -Math.PI * 0.25,
                  orbitLong: -Math.PI * 0.3,
                  zoom: 18,
                }}
              >
                <Highlight>
                  <RootObject transparent isButton />
                </Highlight>
              </Hotspot>

              <Hotspot
                name="buntspecht"
                cam={{
                  orbitLat: -Math.PI * 0.08,
                  orbitLong: -Math.PI * 0.5,
                  zoom: 15,
                }}
              >
                <Highlight>
                  <Button size={0.3} />
                  <RootObject />
                </Highlight>
              </Hotspot>

              <Hotspot
                name="magnolie"
                cam={{
                  orbitLat: -Math.PI * 0.08,
                  orbitLong: -Math.PI * 0.65,
                  zoom: 22,
                }}
              >
                <SelectionModifier
                  names={React.useMemo(
                    () => ["gartenkunstmuseum", "spiegelweiher"],
                    []
                  )}
                  affect="interactivity"
                  additive
                >
                  <Highlight>
                    <Button size={0.4} />
                    <RootObject />
                  </Highlight>
                </SelectionModifier>
              </Hotspot>

              <Schlafzimmer />
              <Gesellschaftsraum />
            </EnableOnActiveOrParent>
          </Hotspot>

          <Hotspot
            name="kuechengarten"
            objectName="beetbepflanzung"
            cam={{
              orbitLat: -0.25 * Math.PI,
              orbitLong: -0.15 * Math.PI,
              zoom: 40,
            }}
          >
            <Highlight>
              <RootObject transparent isButton />
            </Highlight>
          </Hotspot>

          <Hotspot
            name="gartenkunstmuseum"
            objectName="museum_fuer_gartenkunst"
            cam={{
              orbitLat: -0.08 * Math.PI,
              orbitLong: -0.25 * Math.PI,
              zoom: 42,
            }}
          >
            <Button size={0.667} shaded position={new Vector3(0, 1.5, 0)} />

            <EnableOnActiveOrParent>
              <Hotspot
                name="innenhof-ostfluegel"
                objectName="elisabeth_von_ardenne"
                cam={{
                  orbitLat: -0.2 * Math.PI,
                  orbitLong: -0.2 * Math.PI,
                  zoom: 17,
                }}
              >
                <SelectionModifier
                  names={frontHotspots}
                  affect="interactivity"
                  additive
                >
                  <Highlight>
                    <Button size={0.3} />
                    <HotspotMesh />
                  </Highlight>
                </SelectionModifier>
              </Hotspot>
            </EnableOnActiveOrParent>
          </Hotspot>

          <Hotspot
            name="naturkundemuseum"
            cam={{
              orbitLat: -0.08 * Math.PI,
              orbitLong: 0.25 * Math.PI,
              zoom: 42,
            }}
          >
            <Button size={0.667} shaded position={new Vector3(0, 1.5, 0)} />

            <EnableOnActiveOrParent>
              <Hotspot
                name="innenhof-nkm"
                cam={{
                  orbitLat: -0.2 * Math.PI,
                  orbitLong: 0.2 * Math.PI,
                  zoom: 17,
                }}
              >
                <SelectionModifier
                  names={frontHotspots}
                  affect="interactivity"
                  additive
                >
                  <Highlight>
                    <Button size={0.3} />
                    <HotspotMesh />
                  </Highlight>
                </SelectionModifier>
              </Hotspot>
            </EnableOnActiveOrParent>
          </Hotspot>
        </Hotspot>
      </Hotspot>
    </HighlightHinterProvider>
  );
}
