import React from "react";

export function MuteIcon({ muted }: { muted?: boolean }) {
  return (
    <svg
      width="256"
      height="214"
      viewBox="0 0 256 214"
      fill="black"
      className="w-full h-auto"
    >
      {muted ? (
        <path d="M205.046 106.6L225.318 86.3285C228.116 83.5303 228.116 78.9909 225.318 76.1926L215.182 66.0567C212.383 63.2584 207.844 63.2584 205.046 66.0567L184.774 86.3285L164.502 66.0567C161.704 63.2584 157.164 63.2584 154.366 66.0567L144.23 76.1926C141.432 78.9909 141.432 83.5303 144.23 86.3285L164.502 106.6L144.235 126.868C141.436 129.666 141.436 134.205 144.235 137.004L154.371 147.14C157.169 149.938 161.708 149.938 164.507 147.14L184.774 126.872L205.046 147.144C207.844 149.942 212.383 149.942 215.182 147.144L225.318 137.008C228.116 134.21 228.116 129.67 225.318 126.872L205.046 106.6Z" />
      ) : (
        <>
          <path d="M184.418 4.85237C187.674 -0.117866 194.341 -1.49035 199.302 1.7654C234.8 25.0665 256 64.2598 256 106.602C256 148.945 234.8 188.143 199.302 211.444C194.159 214.824 187.545 213.114 184.418 208.357C181.158 203.4 182.544 196.729 187.505 193.477C216.94 174.16 234.507 141.687 234.507 106.607C234.507 71.5264 216.94 39.0533 187.505 19.7364C182.544 16.4807 181.158 9.81373 184.418 4.85237Z" />
          <path d="M175.264 37.2056C199.12 52.4405 213.36 78.3844 213.36 106.602C213.36 134.825 199.12 160.764 175.264 175.999C169.934 179.41 163.444 177.287 160.553 172.686C157.404 167.671 158.874 161.04 163.844 157.868C181.5 146.591 192.04 127.429 192.04 106.602C192.04 85.7753 181.5 66.6139 163.844 55.3365C158.874 52.1607 157.404 45.5293 160.553 40.5191C163.702 35.5088 170.293 34.0342 175.264 37.2056Z" />
          <path d="M135.906 76.6566C138.757 71.4998 145.247 69.6476 150.39 72.4592C162.934 79.3616 170.724 92.4379 170.724 106.598C170.724 120.758 162.933 133.839 150.395 140.746C145.26 143.566 138.766 141.727 135.91 136.548C133.072 131.391 134.951 124.911 140.108 122.064C145.837 118.915 149.4 112.99 149.4 106.602C149.4 100.22 145.837 94.29 140.103 91.1409C134.946 88.2938 133.068 81.8134 135.906 76.6566Z" />
        </>
      )}
      <path d="M55.9918 63.9583L95.5094 24.4496C102.176 17.7826 113.707 22.4553 113.707 31.9871V181.21C113.707 190.733 102.185 195.423 95.5094 188.747L55.9918 149.239H10.66C4.77036 149.239 0 144.464 0 138.579V74.6184C0 68.7287 4.77036 63.9583 10.66 63.9583H55.9918Z" />
    </svg>
  );
}
