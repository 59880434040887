import React from "react";

import logoSrc from "../../assets/logo.svg";

export function Logo(): JSX.Element {
  return (
    <div className="w-24">
      <img src={logoSrc} className="w-full h-auto" draggable={false} />
    </div>
  );
}
