import React from "react";

export type AudioContext = {
  muted: boolean;
  setMuted(value: boolean): void;
};

const initialContext: AudioContext = {
  muted: false,
  setMuted() {},
};

const Context = React.createContext(initialContext);

export const useAudioContext = () => React.useContext(Context);
const storageKey = "AUDIO_MUTED";

export const AudioContextProvider: React.FC<{ muted?: boolean }> = ({
  children,
  muted: mutedProp,
}) => {
  const [muted, setMuted] = React.useState(
    typeof localStorage !== "undefined" && !!localStorage.getItem(storageKey)
  );
  const [windowBlurred, setWindowBlurred] = React.useState(false);

  React.useEffect(() => {
    const handleBlur = () => setWindowBlurred(true);
    const handleFocus = () => setWindowBlurred(false);

    window.addEventListener("blur", handleBlur);
    window.addEventListener("focus", handleFocus);

    return () => {
      window.removeEventListener("blur", handleBlur);
      window.removeEventListener("focus", handleFocus);
    };
  }, []);

  return (
    <Context.Provider
      value={React.useMemo<AudioContext>(
        () => ({
          muted: mutedProp || muted || windowBlurred,
          setMuted(value) {
            setMuted(value);
            if (value) localStorage.setItem(storageKey, "1");
            else localStorage.removeItem(storageKey);
          },
        }),
        [muted, windowBlurred, mutedProp]
      )}
    >
      {children}
    </Context.Provider>
  );
};

export const AudioContextAmplifier = Context.Provider;
