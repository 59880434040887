import React from "react";
import { Color } from "three";

import {
  ParentModifier,
  EnableOnActiveOrParent,
  SelectionModifier,
} from "../../../../lib/hotspots";
import { useVector3 } from "../../../../lib/util";

import { HotspotButton as Button } from "../../button";
import { HotspotLight as Light } from "../../light";
import { Hotspot } from "../../hotspot";
import { Highlight } from "../highlight";
import { HotspotTransition } from "../../transition";
import { HotspotRootObject as RootObject } from "../../root-object";

export function Room() {
  return (
    <Hotspot
      name="gartensaal-ea"
      cam={{
        orbitLat: -0.2,
        orbitLong: -Math.PI,
        zoom: 22,
      }}
    >
      <Light
        distance={10}
        intensity={0.6}
        color={new Color(1, 0.95, 0.8)}
        position={useVector3(0.2, 1.2, 0.8)}
      />
      <ParentModifier affect="interactivity">
        <SelectionModifier names="kuppelsaal" affect="interactivity" additive>
          <Highlight>
            <Button size={0.7} />
            <RootObject disableGlow />

            <HotspotTransition
              objectName="gartensaalwand"
              position={useVector3(6.1, 0, 0)}
            />

            <EnableOnActiveOrParent>
              <Hotspot
                name="apollo-und-seine-musen"
                cam={{
                  orbitLat: Math.PI / 12,
                  orbitLong: -Math.PI,
                  zoom: 8,
                }}
              >
                <Highlight>
                  <RootObject isButton />
                </Highlight>
              </Hotspot>

              <Hotspot
                name="spiegel"
                cam={{
                  orbitLat: 0,
                  orbitLong: -Math.PI,
                  zoom: 6,
                }}
              >
                <Highlight>
                  <RootObject isButton />
                </Highlight>
              </Hotspot>
            </EnableOnActiveOrParent>
          </Highlight>
        </SelectionModifier>
      </ParentModifier>
    </Hotspot>
  );
}
