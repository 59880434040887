import React from "react";
import { Vector3 } from "three";
import { useHotspotScope } from "./hotspot";

import { ShadedLink, SphereLink } from "../../link";

export type HotspotButtonProps = {
  size: number;
  position?: Vector3;
  shaded?: boolean;
  relativeToPivot?: boolean;
};

export function HotspotButton({
  position,
  size,
  shaded,
  relativeToPivot,
}: HotspotButtonProps) {
  const scope = useHotspotScope();

  return (
    scope && (
      <group
        position={
          relativeToPivot
            ? scope.data.origin || new Vector3()
            : scope.data.focusPoint
        }
      >
        <group position={position} scale={[size, size, size]}>
          {shaded ? (
            <ShadedLink
              invisible={!scope.interactive}
              to={`/${scope.name}/`}
              setHovering={scope.setHovering}
            />
          ) : (
            <SphereLink
              disabled={!scope.interactive}
              to={`/${scope.name}/`}
              setHovering={scope.setHovering}
            />
          )}
        </group>
      </group>
    )
  );
}
