import React from "react";
import { Vector3, Object3D, Vector3Tuple } from "three";
import { useSpring, config } from "@react-spring/three";

type TransitionState = {
  position?: Vector3;
  rotation?: Vector3;
};

export type TransitionProps = {
  object: Object3D;
  evolution: number;
  position?: Vector3;
  rotation?: Vector3;
  soundSrc?: string;
};

function Spring({
  state,
  object,
}: {
  state: TransitionState;
  object: Object3D;
}) {
  const initialState = React.useRef(state);
  React.useEffect(() => {
    const { position, rotation } = initialState.current;
    if (position) object.position.copy(position);
    if (rotation) object.rotation.setFromVector3(rotation);
  }, [object]);

  useSpring({
    position: state.position?.toArray(),
    rotation: state.rotation?.toArray(),
    config: config.molasses,
    onChange({ value: { position, rotation } }) {
      if (position) object.position.set(...(position as Vector3Tuple));
      if (rotation) object.rotation.setFromVector3(new Vector3(...rotation));
    },
  });

  return null;
}

export function Transition({
  object,
  evolution,
  position,
  rotation,
}: TransitionProps) {
  const [startState, setStartState] = React.useState<
    TransitionState | undefined
  >();

  React.useEffect(() => {
    if (!object) return;

    const initialPosition = position && new Vector3().copy(object.position);
    const initialRotation = rotation && object.rotation.toVector3();

    setStartState({ position: initialPosition, rotation: initialRotation });

    return () => {
      if (initialPosition) object.position.copy(initialPosition);
      if (initialRotation) object.rotation.setFromVector3(initialRotation);
    };
  }, [object, position, rotation]);

  const endState = React.useMemo<TransitionState | undefined>(
    () =>
      startState && {
        position:
          position && new Vector3().copy(startState.position!).add(position),
        rotation:
          rotation && new Vector3().copy(startState.rotation!).add(rotation),
      },
    [startState, position, rotation]
  );

  const state = React.useMemo<TransitionState | undefined>(
    () =>
      startState && {
        position: startState.position
          ?.clone()
          .lerp(endState!.position!, evolution),
        rotation: startState.rotation
          ?.clone()
          .lerp(endState!.rotation!, evolution),
      },
    [evolution, startState, endState]
  );

  return object && state ? <Spring object={object} state={state} /> : null;
}
