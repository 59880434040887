import React from "react";
import type { WrapPageElementNodeArgs } from "gatsby";

import { GatsbyContextProvider, PageContext, PageData } from "./context";
import { SPA } from "../components/spa";

import "./global.css";

export function wrapPageElement({
  element,
  props,
}: WrapPageElementNodeArgs<PageData, PageContext>): JSX.Element {
  return (
    <GatsbyContextProvider context={props.pageContext} data={props.data}>
      {props.pageContext.show3dContent && <SPA />}
      {element}
    </GatsbyContextProvider>
  );
}
