import React from "react";
import { useSpring } from "@react-spring/three";
import {
  Color,
  Group,
  MeshPhysicalMaterial,
  MeshStandardMaterial,
} from "three";

import { useMeshes } from "../gltf";

const defaultGlowColor = new Color(0xffffff);

export const GlowOnHover: React.FC<{ glowColor?: Color }> = ({
  children,
  glowColor,
}) => {
  const [hovering, setHovering] = React.useState(false);
  const ref = React.useRef<Group>(null);
  const meshes = useMeshes(ref.current);

  const baseColors = React.useMemo(
    () =>
      meshes.map(
        (mesh) => new Color((mesh.material as MeshStandardMaterial).emissive)
      ),
    [meshes]
  );

  useSpring({
    x: hovering ? 1 : 0,
    onChange({ value }) {
      meshes.forEach((mesh, i) => {
        (mesh.material as MeshPhysicalMaterial).emissive.lerpColors(
          baseColors[i],
          glowColor || defaultGlowColor,
          value.x
        );
      });
    },
  });

  return (
    <group
      onPointerOver={() => setHovering(true)}
      onPointerOut={() => setHovering(false)}
      ref={ref}
    >
      {children}
    </group>
  );
};
