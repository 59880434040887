import React from "react";
import cn from "classnames";

import { useHotspotPageData } from "../../../templates/hotspot";
import { HotspotType } from "../../../templates/hotspot";
import * as styles from "./card.module.css";

import { useCardAudio } from "./audio";
import { CardHeader } from "./header";
import { CardContent } from "./content";
import { CardFooter } from "./footer";
import { useCardVisibility } from ".";
import { CardTabs, CardTabProvider, useCardTab } from "./tabs";

function hotspotTypeToCardStyle(type?: HotspotType): string {
  switch (type) {
    case HotspotType.Flora:
      return "c";
    case HotspotType.Fauna:
      return "b";
    case HotspotType.Exhibit:
    default:
      return "a";
  }
}

function CardComp({ prevPath }: { prevPath: string | null }) {
  const tabCtrl = useCardTab();
  const data = useHotspotPageData()!;
  const { childHotspot } = data.file;

  return (
    <>
      <CardHeader
        title={
          tabCtrl?.current?.childMarkdownRemark.frontmatter.title ||
          childHotspot.title
        }
        prevPath={prevPath}
      />
      {tabCtrl && <CardContent {...tabCtrl.current.childMarkdownRemark} />}
      <CardFooter data={childHotspot} />
    </>
  );
}

export function Card({ prevPath }: { prevPath: string | null }) {
  useCardAudio();
  const visible = useCardVisibility();
  const data = useHotspotPageData();

  return (
    <div
      className={`${styles.frame} fixed inset-0 flex justify-end pointer-events-none z-20`}
    >
      <div
        className="w-full max-w-xl 2xl:max-w-2xl pl-16"
        style={{ transformStyle: "preserve-3d" }}
      >
        <article
          className={cn(
            styles.card,
            "relative pointer-events-auto h-full bg-card-bg shadow-lg",
            visible && styles.visible
          )}
          data-style={hotspotTypeToCardStyle(data?.file.childHotspot.type)}
        >
          {data && (
            <CardTabProvider data={data}>
              <CardTabs data={data} />
              <div className="flex flex-col h-full">
                <CardComp prevPath={prevPath} />
              </div>
            </CardTabProvider>
          )}
        </article>
      </div>
    </div>
  );
}
