import { useFrame } from "@react-three/fiber";
import React from "react";
import { AnimationMixer, LoopRepeat, AnimationClip, Object3D } from "three";

export type LoopAnimationProps = {
  clip: AnimationClip;
  root: Object3D;
  paused?: boolean;
};

export function useLoopAnimation({ root, clip, paused }: LoopAnimationProps) {
  const mixer = React.useMemo<AnimationMixer | null>(() => {
    if (!clip) return null;

    const mixer = new AnimationMixer(root);
    const action = mixer.clipAction(clip!);
    action.loop = LoopRepeat;
    action.play();

    return mixer;
  }, [root, clip]);

  useFrame((_, delta) => {
    if (mixer && !paused) mixer.update(delta);
  });
}

export function LoopAnimation(props: LoopAnimationProps) {
  useLoopAnimation(props);
  return null;
}
