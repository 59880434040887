import React from "react";
import { useThree } from "@react-three/fiber";

export type CursorStyle = Exclude<
  React.CSSProperties["cursor"],
  undefined | "auto"
>;
type CursorStyleEntry = { value: CursorStyle };

const stack: CursorStyleEntry[] = [];
let overridingStyle: CursorStyle | null = null;

export function useCursorStyle(style: CursorStyle | null, override = false) {
  const {
    gl: { domElement },
  } = useThree();

  React.useEffect(() => {
    if (!style) return;

    if (override) {
      overridingStyle = domElement.style.cursor = style;

      return () => {
        domElement.style.cursor = stack[stack.length - 1]?.value || "auto";
        overridingStyle = null;
      };
    } else {
      const entry: CursorStyleEntry = { value: style };
      stack.push(entry);

      if (!overridingStyle) domElement.style.cursor = style;
      return () => {
        const stackId = stack.indexOf(entry);
        if (!overridingStyle) {
          if (stack.length === 1) {
            domElement.style.cursor = "auto";
          } else if (stackId === stack.length - 1) {
            domElement.style.cursor = stack[stackId - 1].value;
          }
        }
        stack.splice(stackId, 1);
      };
    }
  }, [style, override, domElement]);
}
