import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import SwiperCore, { Pagination } from "swiper";
import "./swiper.css";
SwiperCore.use([Pagination]);

import { CardMedia, CardMediaData } from "./media";

const isServer = typeof window == "undefined";

export function CardSwiper({ data }: { data: CardMediaData[] }) {
  const paginationRef = React.useRef<HTMLDivElement>(null);
  const paginationElem = React.useMemo<HTMLDivElement | null>(
    () => (!isServer ? document.createElement("div") : null),
    []
  );
  const key = React.useMemo(() => {
    if (!data.length) return "";
    const media = data[0];
    return media.kind == "image"
      ? media.data.images.fallback?.src || ""
      : media.src;
  }, [data]);

  React.useEffect(() => {
    const wrapper = paginationRef.current!;
    wrapper.appendChild(paginationElem!);
    return () => void wrapper.removeChild(paginationElem!);
  }, [paginationElem]);

  return (
    <div className="card-swiper">
      <Swiper
        autoHeight={true}
        pagination={{ clickable: true, el: paginationElem }}
        key={key}
      >
        {data.map((data, i) =>
          !isServer ? (
            <SwiperSlide key={i}>
              {({ isActive }) => (
                <CardMedia data={data} invisible={!isActive} />
              )}
            </SwiperSlide>
          ) : (
            <CardMedia data={data} />
          )
        )}
      </Swiper>
      <div className="text-center py-2" ref={paginationRef}></div>
    </div>
  );
}
