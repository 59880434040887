export type TreeNodeData = Record<string, unknown>;

export class TreeNodeObject<T extends TreeNodeData> {
  children: TreeNodeObject<T>[] = [];
  data: T | null = null;
  name: string;

  constructor(name: string) {
    this.name = name;
  }

  private _level = 0;
  get level() {
    return this._level;
  }
  set level(value: number) {
    this._level = value;
    this.children.forEach((child) => void (child.level = this._level + 1));
  }

  private _parent: TreeNodeObject<T> | null = null;
  get parent() {
    return this._parent;
  }
  set parent(value: TreeNodeObject<T> | null) {
    if (this._parent === value) return;

    if (this._parent)
      this._parent.children.splice(this._parent.children.indexOf(this), 1);
    if (value) value.children.push(this);

    this._parent = value;
    this.level = value ? value.level + 1 : 0;
  }

  findChild(name: string, depth = 1): TreeNodeObject<T> | null {
    const result = this.children.find((child) => child.name === name) || null;
    if (result) return result;

    if (depth > 1) {
      for (const child of this.children) {
        const result = child.findChild(name, depth - 1);
        if (result) return result;
      }
    }

    return null;
  }

  findParent(name: string): TreeNodeObject<T> | null {
    return this.parent
      ? this.parent.name === name
        ? this.parent
        : this.parent.findParent(name)
      : null;
  }

  getRoot(): TreeNodeObject<T> {
    let root: TreeNodeObject<T> = this;
    while (root.parent) root = root.parent;
    return root;
  }

  traverse(fn: (node: TreeNodeObject<T>) => void) {
    this.children.forEach((child) => child.traverse(fn));
    fn(this);
  }
}
