import React from "react";
import { useSpring } from "@react-spring/three";

import {
  useShaderModificationOnMesh,
  FloatUniform,
  ModificationOnMeshProps,
} from "../modification";
import { Shader } from "three";
export type GlowEffectProps = Omit<
  ModificationOnMeshProps,
  "onCompile" | "uniforms"
> & { strength?: number };
export type GlowEffectUniforms = { glowStrength: FloatUniform };

export function useGlowEffectSpring(
  strength: number,
  onChange: (value: number) => void
) {
  useSpring({
    strength,
    onChange: (result) => onChange(result.value.strength),
  });
}

function useGlowEffectUniforms(strength = 1) {
  const uniforms = React.useMemo<{ glowStrength: FloatUniform }>(
    () => ({
      glowStrength: { value: 0, type: "float" },
    }),
    []
  );

  useGlowEffectSpring(
    strength,
    (value) => void (uniforms.glowStrength.value = value)
  );

  return uniforms;
}

function handleCompile(shader: Shader) {
  shader.fragmentShader = shader.fragmentShader.replace(
    "#include <emissivemap_fragment>",
    "totalEmissiveRadiance = vec3(glowStrength);"
  );
}

export function useGlowEffect({ strength, ...props }: GlowEffectProps) {
  const uniforms = useGlowEffectUniforms(strength);
  useShaderModificationOnMesh({ ...props, onCompile: handleCompile, uniforms });
}

export function GlowEffect(props: GlowEffectProps) {
  useGlowEffect(props);
  return null;
}
