import React from "react";
import { HotspotScope, useHotspotScope } from "..";
import { HotspotScopeAmplifier } from "../scope";

export type ScopeModifierProps = {
  affect: "interactivity" | "visibility";
  additive?: boolean;
  value: boolean;
};

export const ScopeModifier: React.FC<ScopeModifierProps> = ({
  children,
  affect,
  value,
  additive,
}) => {
  const scope = useHotspotScope();

  const modifiedScope = React.useMemo<HotspotScope | null>(() => {
    if (!scope) return null;

    const valueName = affect === "visibility" ? "visible" : "interactive";
    const originalValue = scope[valueName];

    return {
      ...scope,
      [valueName]: additive ? value || originalValue : value && originalValue,
    };
  }, [value, scope, affect, additive]);

  return (
    <HotspotScopeAmplifier value={modifiedScope}>
      {children}
    </HotspotScopeAmplifier>
  );
};
