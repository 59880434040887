import React from "react";

export type PageData = Record<string, unknown> | undefined;
export type PageContext = {
  pagePath: string;
  show3dContent: boolean;
  cardTabIndex?: number;
  lexiconSlugs?: string[] | null;
};

const GatsbyContext = React.createContext<{
  context: PageContext;
  data: PageData;
} | null>(null);

export const useGatsbyContext = () => React.useContext(GatsbyContext)!;

export const usePageContext = (): PageContext => useGatsbyContext().context;
export const usePageData = (): PageData => useGatsbyContext().data;

export const GatsbyContextProvider: React.FC<{
  context: PageContext;
  data: PageData;
}> = ({ children, context, data }) => (
  <GatsbyContext.Provider
    value={React.useMemo(() => ({ context, data }), [context, data])}
  >
    {children}
  </GatsbyContext.Provider>
);

export const GatsbyContextAmplifier = GatsbyContext.Provider;
