import React from "react";
import { Color, Mesh, SphereGeometry } from "three";
import { useFrame } from "@react-three/fiber";
import { useSpring, animated } from "@react-spring/three";

import { ButtonProps } from ".";
import { SphereButton } from "./sphere-button";

export type ShadedButtonProps = ButtonProps & {
  invisible?: boolean;
  color?: string | Color;
};

const orbGeometry = new SphereGeometry(1, 24, 16);

let count = 0;

export function ShadedButton({
  onClick,
  disabled,
  setHovering: setParentHovering,
  invisible,
  color,
}: ShadedButtonProps) {
  const id = React.useMemo(() => count++, []);
  const [hovering, setHovering] = React.useState(false);

  const fadeProps = useSpring({
    scale: new Array(3).fill(invisible ? 0 : hovering ? 1.4 : 1) as [
      number,
      number,
      number
    ],
  });

  const orbRef = React.useRef<Mesh>();
  useFrame(() => {
    const mesh = orbRef.current!;
    const t = Math.PI * (Date.now() / 1000 + (2 / 3) * (1 + (id % 3)));
    const val = 1 + 0.15 * Math.sin(t);
    mesh.scale.set(val, val, val);
  });

  return (
    <group scale={[0.25, 0.25, 0.25]}>
      <SphereButton
        onClick={onClick}
        setHovering={React.useCallback(
          (value) => {
            setHovering(value);
            if (setParentHovering) setParentHovering(value);
          },
          [setParentHovering]
        )}
        disabled={invisible || disabled}
      />
      <group scale={[0.5, 0.5, 0.5]}>
        <animated.group {...fadeProps}>
          <mesh geometry={orbGeometry} ref={orbRef} castShadow>
            <meshStandardMaterial color={color || "white"} roughness={0.6} />
          </mesh>
        </animated.group>
      </group>
    </group>
  );
}
