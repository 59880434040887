import React from "react";

import * as styles from "./gradient.module.css";

export function BgGradient() {
  return (
    <div
      className={`${styles.gradient} fixed inset-0 pointer-events-none`}
    ></div>
  );
}
