import React from "react";
import type { StandardMesh } from "./util";

export function useMaterialDuplication(mesh: StandardMesh) {
  const material = React.useMemo(() => mesh.material.clone(), [mesh]);

  React.useEffect(() => {
    const baseMat = mesh.material;
    mesh.material = material;
    return () => void (mesh.material = baseMat);
  }, [material, mesh]);

  return material;
}
