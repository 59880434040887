import React from "react";
import { Vector3 } from "three";

import {
  HotspotScopeProvider,
  HotspotScopeProps,
  HotspotScope as HotspotScopeBase,
  HotspotNodeObject as HotspotNodeObjectBase,
  useCurrentHotspot as useCurrentHotspotBase,
  useHotspotScope as useHotspotScopeBase,
} from "../../lib/hotspots";
import { useGltfContext } from "../context";

export type HotspotData = {
  origin: Vector3 | null;
  focusPoint: Vector3;
  cam: {
    orbitLat: number;
    orbitLong: number;
    zoom: number;
  };
};

export type HotspotNodeObject = HotspotNodeObjectBase<HotspotData>;
export type HotspotScope = HotspotScopeBase<HotspotData>;
export const useHotspotScope = () => useHotspotScopeBase<HotspotData>();
export const useCurrentHotspot = () => useCurrentHotspotBase<HotspotData>();

function usePositionFromObject(
  objectName: string,
  fallback?: Vector3
): Vector3 | null {
  const ctx = useGltfContext();

  return (
    React.useMemo<Vector3 | null>(() => {
      if (!ctx) return null;

      const object = ctx.gltf.nodes[objectName];

      return object
        ? object
            .getWorldPosition(new Vector3())
            .multiplyScalar(ctx.scaleFactor || 1)
        : null;
    }, [ctx, objectName]) ||
    fallback ||
    null
  );
}

type HotspotCoreProps = Omit<HotspotScopeProps<any>, "data"> & HotspotData;

const HotspotCore: React.FC<HotspotCoreProps> = ({
  origin: position,
  focusPoint,
  cam,
  ...props
}) => {
  const { orbitLat, orbitLong, zoom } = cam;
  const data = React.useMemo<HotspotData>(
    () => ({
      name: props.name,
      origin: position,
      focusPoint,
      cam: { orbitLat, orbitLong, zoom },
    }),
    [props.name, position, focusPoint, orbitLat, orbitLong, zoom]
  );

  return <HotspotScopeProvider<HotspotData> {...props} data={data} />;
};

export type HotspotProps = Omit<HotspotCoreProps, "focusPoint" | "origin"> & {
  focusPoint?: Vector3;
  focusPointObjectName?: string;
  objectName?: string;
};

const ObjectNameContext = React.createContext("");
export const useHotspotObjectName = () => React.useContext(ObjectNameContext);

export const Hotspot: React.FC<HotspotProps> = ({
  focusPoint: focusPointProp,
  focusPointObjectName,
  objectName: objectNameProp,
  ...props
}) => {
  const objectName = React.useMemo(
    () => objectNameProp || props.name.replace(/-/g, "_"),
    [objectNameProp, props.name]
  );

  const origin = usePositionFromObject(objectName);

  const foundFocusPoint = usePositionFromObject(
    focusPointObjectName || `${objectName}_center`
  );

  const focusPoint = focusPointProp || foundFocusPoint;

  return (
    focusPoint && (
      <ObjectNameContext.Provider value={objectName}>
        <HotspotCore {...props} origin={origin} focusPoint={focusPoint} />
      </ObjectNameContext.Provider>
    )
  );
};
