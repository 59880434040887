import React from "react";

import {
  ParentModifier,
  EnableOnActiveOrParent,
  SelectionModifier,
} from "../../../../lib/hotspots";
import { useVector3 } from "../../../../lib/util";

import { HotspotButton as Button } from "../../button";
import { HotspotLight as Light } from "../../light";
import { Hotspot } from "../../hotspot";
import { Highlight } from "../highlight";
import { HotspotTransition } from "../../transition";
import { HotspotRootObject as RootObject } from "../../root-object";
import { Color } from "three";

const activeNames = ["gartensaal-ea", "jagdachse"];

export function Room() {
  return (
    <Hotspot
      name="kuppelsaal"
      cam={{
        orbitLat: -0.2,
        orbitLong: -Math.PI,
        zoom: 22,
      }}
    >
      <Light
        distance={14}
        intensity={0.7}
        color={new Color(1, 0.95, 0.8)}
        position={useVector3(0, 3, 2.1)}
      />
      <ParentModifier affect="interactivity">
        <SelectionModifier names={activeNames} affect="interactivity" additive>
          <Highlight>
            <Button size={0.4} position={useVector3(0, -0.1, 0)} />
            <RootObject disableGlow />

            <HotspotTransition
              objectName="kuppelsaalwand_r"
              rotation={useVector3(1.76, 0.65, 0.6)}
            />
            <HotspotTransition
              objectName="kuppelsaalwand_cr"
              rotation={useVector3(1.76, 0, 0)}
            />
            <HotspotTransition
              objectName="kuppelsaalwand_cl"
              rotation={useVector3(1.76, 0, 0)}
            />
            <HotspotTransition
              objectName="kuppelsaalwand_l"
              rotation={useVector3(1.76, -0.65, -0.6)}
            />

            <EnableOnActiveOrParent>
              <Hotspot
                name="jagdzug-der-diana"
                objectName="deckengemaelde"
                cam={{
                  orbitLat: Math.PI / 8,
                  orbitLong: -Math.PI,
                  zoom: 8,
                }}
              >
                <Highlight>
                  <RootObject isButton />
                </Highlight>
              </Hotspot>

              <Hotspot
                name="initialen-ea"
                objectName="initialien_ea"
                cam={{
                  orbitLat: 0,
                  orbitLong: -1.25 * Math.PI,
                  zoom: 6,
                }}
              >
                <Highlight>
                  <RootObject isButton />
                </Highlight>
              </Hotspot>

              <Hotspot
                name="initialen-ct"
                objectName="initialien_ct"
                cam={{
                  orbitLat: 0,
                  orbitLong: -0.75 * Math.PI,
                  zoom: 6,
                }}
              >
                <Highlight>
                  <RootObject isButton />
                </Highlight>
              </Hotspot>
            </EnableOnActiveOrParent>
          </Highlight>
        </SelectionModifier>
      </ParentModifier>
    </Hotspot>
  );
}
