import React from "react";
import cn from "classnames";

import * as styles from "./tooltip.module.css";

export type TooltipRegistration = {
  el: Element;
  titleHtml?: string;
  html: string;
};
type TooltipContext = {
  registerTooltipElement(data: TooltipRegistration): () => void;
};

const Context = React.createContext<TooltipContext | null>(null);

export function useTooltipContext() {
  const ctx = React.useContext(Context);
  if (!ctx)
    throw Error('This hook has to be called inside "TooltipOverlay" component');
  return ctx;
}

type Point = [number, number];
const origin: Point = [0, 0];

enum FlipState {
  None = 0,
  X = 1,
  Y = 2,
}

const speed = 0.1;

export const TooltipOverlay: React.FC = ({ children }) => {
  const [currentReg, setCurrentReg] =
    React.useState<TooltipRegistration | null>(null);
  const [active, setActive] = React.useState(false);
  const tooltipRef = React.useRef<HTMLDivElement>(null);

  const [visible, setVisible] = React.useState(active);
  React.useEffect(() => {
    if (active) {
      setVisible(true);
      return;
    }

    const timeout = setTimeout(() => setVisible(false), 1000);
    return () => clearTimeout(timeout);
  }, [active]);

  const registerTooltipElement = React.useCallback<
    TooltipContext["registerTooltipElement"]
  >((reg) => {
    const { el } = reg;
    el.classList.add(styles.target);

    const handleEnter = () => {
      setActive(true);
      setCurrentReg(reg);
    };

    const handleLeave = () => setActive(false);

    el.addEventListener("mouseenter", handleEnter);
    el.addEventListener("mouseleave", handleLeave);

    return () => {
      el.classList.remove(styles.target);
      handleLeave();
      el.removeEventListener("mouseenter", handleEnter);
      el.removeEventListener("mouseleave", handleLeave);
    };
  }, []);

  const ctx = React.useMemo<TooltipContext>(
    () => ({ registerTooltipElement }),
    [registerTooltipElement]
  );

  const mousePosRef = React.useRef(origin);
  const [flipState, setFlipState] = React.useState(FlipState.None);

  React.useEffect(() => {
    const handleMove = ({ clientX, clientY }: MouseEvent) => {
      mousePosRef.current = [clientX, clientY];
      let state = FlipState.None;
      if (clientX > window.innerWidth / 2) state |= FlipState.X;
      if (clientY > window.innerHeight / 2) state |= FlipState.Y;
      setFlipState(state);
    };

    window.addEventListener("mousemove", handleMove);
    return () => window.removeEventListener("mousemove", handleMove);
  }, []);

  const posRef = React.useRef<Point | null>(null);

  React.useEffect(() => {
    if (!visible) return;
    let mounted = true;

    const draw = () => {
      if (!mounted) return;
      requestAnimationFrame(draw);

      let p = posRef.current;
      const m = mousePosRef.current;
      if (!p) p = m;
      const np = (posRef.current = [
        p[0] + speed * (m[0] - p[0]),
        p[1] + speed * (m[1] - p[1]),
      ]);

      tooltipRef.current!.style.transform = `translate(${Math.round(
        np[0]
      )}px, ${Math.round(np[1])}px)`;
    };

    draw();

    return () => {
      mounted = false;
      posRef.current = null;
    };
  }, [visible]);

  return (
    <>
      <div className={styles.main} style={{ zIndex: 100 }}>
        <div className={styles.transform} ref={tooltipRef}>
          <div
            className={`${styles.transition}${
              active ? ` ${styles.transitionActive}` : ""
            }`}
          >
            <div
              className={cn(
                styles.tooltip,
                flipState & FlipState.X && styles.flippedX,
                flipState & FlipState.Y && styles.flippedY
              )}
            >
              <div className="max-w-sm w-full bg-white py-3 px-4 rounded-lg shadow-lg border border-black">
                {currentReg && (
                  <>
                    {currentReg.titleHtml && (
                      <div
                        className="mb-1 text-lg font-bold"
                        dangerouslySetInnerHTML={{
                          __html: currentReg.titleHtml,
                        }}
                      />
                    )}
                    <div
                      className="inline-block"
                      dangerouslySetInnerHTML={{ __html: currentReg.html }}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Context.Provider value={ctx}>{children}</Context.Provider>
    </>
  );
};

export const TooltipTarget: React.FC<
  Omit<TooltipRegistration, "el"> & { className?: string }
> = ({ children, className, ...data }) => {
  const { registerTooltipElement } = useTooltipContext();
  const dataRef = React.useRef(data);
  const ref = React.useRef<HTMLDivElement>(null);

  React.useEffect(
    () => registerTooltipElement({ ...dataRef.current, el: ref.current! }),
    [registerTooltipElement]
  );

  return (
    <div className={className} ref={ref}>
      {children}
    </div>
  );
};
