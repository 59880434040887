import React from "react";

import { usePreloadedAsset, PreloadAsset } from "../../../../lib/preloader";
import {
  createGltfContext,
  GLTF,
  useGLTFSanitization,
  GltfContext,
  loadGLTF,
} from "../../lib/gltf";
import {
  HotspotContext,
  HotspotContextProvider,
  HotspotContextAmplifier,
  useHotspotContext,
} from "../../lib/hotspots";

import gltfSrc from "./data.glb";

const IS_IOS =
  typeof navigator !== "undefined" &&
  (/iPad|iPhone|iPod/.test(navigator.userAgent || "") ||
    (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1));

if (IS_IOS) {
  // @ts-ignore
  window.createImageBitmap = undefined;
}

export const preloadAsset: PreloadAsset<GLTF> = {
  src: gltfSrc,
  size: 23_005_448,
  loader: loadGLTF,
};

const { useGltf, GltfContextProvider, useGltfContext, GltfObject } =
  createGltfContext();

export { useGltf, useGltfContext, GltfObject };

export const MainSceneContextProvider: React.FC = ({ children }) => {
  const gltf = usePreloadedAsset<GLTF>(gltfSrc);
  useGLTFSanitization(gltf);

  const gltfCtx = React.useMemo<GltfContext | null>(
    () => gltf && { gltf, scaleFactor: 100 },
    [gltf]
  );

  return (
    <HotspotContextProvider>
      <GltfContextProvider value={gltfCtx}>{children}</GltfContextProvider>;
    </HotspotContextProvider>
  );
};

type MainSceneContext = {
  gltfContext: GltfContext | null;
  hotspotContext: HotspotContext | null;
};

export function useMainSceneContext() {
  const hotspotContext = useHotspotContext();
  const gltfContext = useGltfContext();

  return React.useMemo<MainSceneContext | null>(
    () => gltfContext && hotspotContext && { gltfContext, hotspotContext },
    [gltfContext, hotspotContext]
  );
}

export const MainSceneContextAmplifier: React.FC<{
  value: MainSceneContext | null;
}> = ({ children, value }) => (
  <GltfContextProvider value={value?.gltfContext || null}>
    <HotspotContextAmplifier value={value?.hotspotContext || null}>
      {children}
    </HotspotContextAmplifier>
  </GltfContextProvider>
);
