import React from "react";
import { Link } from "gatsby";
import cn from "classnames";

import { CardSection } from "./section";
import * as styles from "./card.module.css";

export function CardHeader({
  title,
  prevPath,
}: {
  title?: string;
  prevPath: string | null;
}) {
  return (
    <header className={`${styles.pattern} ${styles.text} relative`}>
      <CardSection
        className="relative"
        mid={
          <div className="px-6 py-4">
            <h1
              className={cn(
                styles.bordered,
                styles.heading,
                styles.bg,
                "text-2xl xs:text-3xl font-bold px-3 py-1.5 line-clamp-2"
              )}
            >
              <span>{title || <>&nbsp;</>}</span>
            </h1>
          </div>
        }
        right={
          <Link
            to={prevPath || "/"}
            className={`${styles.bg} flex justify-center items-center box-content w-full h-full`}
          >
            <svg width="32" height="32" viewBox="0 0 32 32">
              <path
                id="Path_8"
                data-name="Path 8"
                d="M16,21.682,5.682,32,0,26.317,10.318,16,0,5.682,5.682,0,16,10.318,26.318,0,32,5.682,21.682,16,32,26.318,26.318,32Z"
                fill="currentColor"
              />
            </svg>
          </Link>
        }
      />
    </header>
  );
}
