import React from "react";
import { Link } from "gatsby";
import cn from "classnames";

import { useGatsbyContext, GatsbyContextAmplifier } from "../../gatsby";
import {
  useCataloguePageData,
  CatalogueItemImageData,
} from "../../overlays/katalog";

import * as styles from "./catalogue.module.css";

type ItemImageData = { src: string; isLandscape: boolean };
type ItemData = {
  title: string;
  excerpt: string;
  path: string;
  image: ItemImageData | null;
};

function resolveImageData(thumbnail: CatalogueItemImageData): ItemImageData {
  const { src, width, height } = thumbnail.childImageSharp.resize;
  return { src, isLandscape: height / width < 0.5 };
}

function Catalogue() {
  const data = useCataloguePageData();

  const items = React.useMemo<ItemData[] | null>(() => {
    if (!data) return null;
    const items: ItemData[] = [];
    data.catalogueData.nodes.forEach((node) => {
      const {
        fields: { pagePath },
        childHotspot,
      } = node;

      node.childHotspot.content?.forEach((content, i) => {
        const {
          frontmatter: {
            title,
            title_in_catalogue,
            hide_in_catalogue,
            thumbnail,
          },
          excerpt,
        } = content.childMarkdownRemark;

        if (hide_in_catalogue) return;

        items.push({
          title: title_in_catalogue || title,
          excerpt,
          path: `${pagePath}${i > 0 ? `${i + 1}/` : ""}`,
          image: thumbnail
            ? resolveImageData(thumbnail)
            : childHotspot.thumbnail
            ? resolveImageData(childHotspot.thumbnail)
            : null,
        });
      });
    });
    return items.sort((a, b) => a.title.localeCompare(b.title));
  }, [data]);

  return (
    <div className="fixed inset-0 overflow-hidden bg-gradient-to-br from-bg to-bg-offset">
      <div className="pt-16 h-full">
        <div className="h-full overflow-y-auto">
          <div className={styles.grid}>
            {items?.map(({ title, excerpt, path, image }, i) => (
              <Link to={path} key={i} className={styles.item}>
                <div className="h-full flex flex-col justify-end pointer-events-none">
                  {image && (
                    <div className="px-16 mb-10">
                      <div className={`${styles.image} relative pt-1/2`}>
                        <div className="absolute inset-0 flex justify-center items-center">
                          <img
                            src={image.src}
                            alt={title}
                            className={
                              image.isLandscape
                                ? "w-full h-auto"
                                : "h-full w-auto"
                            }
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  <h2
                    className={`${styles.heading} text-3xl 3xl:text-4xl font-bold mb-4`}
                  >
                    {title}
                  </h2>
                  <div className="prose 3xl:prose-lg mb-4 line-clamp-3 max-w-xl">
                    <p>{excerpt}</p>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export function CatalogueOverlay() {
  const gatsbyContext = useGatsbyContext();
  const data = useCataloguePageData();
  const dataRef = React.useRef(data);
  if (data) dataRef.current = data;

  const newGatsbyContext = React.useMemo(
    () => ({ ...gatsbyContext, data: dataRef.current }),
    [gatsbyContext]
  );

  return (
    <div
      className={cn(
        "relative z-30 transition duration-1000",
        !data && "opacity-0 pointer-events-none"
      )}
    >
      {dataRef.current ? (
        <GatsbyContextAmplifier value={newGatsbyContext}>
          <Catalogue />
        </GatsbyContextAmplifier>
      ) : null}
    </div>
  );
}
