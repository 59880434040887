import React from "react";
import cn from "classnames";
import { Canvas } from "@react-three/fiber";

import { PreloadAsset } from "../../lib/preloader";
import { useGatsbyContext, GatsbyContextAmplifier } from "../../gatsby";
import { usePreloaderContext, PreloaderAmplifier } from "../preloader";
import { Camera } from "./camera";
import { MainScene } from "./scene";
import { IsReadyProvider, useIsReady } from "../ready-state";
import { useCardVisibility, CardVisibilityAmplifier } from "../ui/card";
import { AudioContextAmplifier, useAudioContext } from "../../lib/audio";

import {
  useMainSceneContext,
  MainSceneContextAmplifier,
  preloadAsset,
} from "./scene";

export { MainSceneContextProvider } from "./scene";
export { useCurrentHotspot } from "./scene/hotspots";
export type { HotspotNodeObject } from "./scene/hotspots";

export const preloadAssets: PreloadAsset<any>[] = [preloadAsset];

export function ThreeContent(): JSX.Element {
  const cardIsVisible = useCardVisibility();
  const isReady = useIsReady();
  const pageContext = useGatsbyContext();
  const preloaderCtx = usePreloaderContext();
  const mainSceneCtx = useMainSceneContext();
  const audioContext = useAudioContext();

  return (
    <div
      className={cn(
        "h-screen relative select-none transition duration-1000",
        !isReady && "opacity-0"
      )}
    >
      <Canvas shadows flat>
        <IsReadyProvider value={isReady}>
          <GatsbyContextAmplifier value={pageContext}>
            <PreloaderAmplifier value={preloaderCtx}>
              <MainSceneContextAmplifier value={mainSceneCtx}>
                <AudioContextAmplifier value={audioContext}>
                  <CardVisibilityAmplifier value={cardIsVisible}>
                    <Camera />
                    <MainScene />
                  </CardVisibilityAmplifier>
                </AudioContextAmplifier>
              </MainSceneContextAmplifier>
            </PreloaderAmplifier>
          </GatsbyContextAmplifier>
        </IsReadyProvider>
      </Canvas>
    </div>
  );
}
