import React from "react";
import { Color } from "three";

import { EnableOnActiveOrParent } from "../../../../lib/hotspots";
import { useVector3 } from "../../../../lib/util";

import { HotspotButton as Button } from "../../button";
import { HotspotLight as Light } from "../../light";
import { Hotspot } from "../../hotspot";
import { Highlight } from "../highlight";
import { HotspotTransition } from "../../transition";
import { HotspotRootObject as RootObject } from "../../root-object";

export function Room() {
  return (
    <Hotspot
      name="gesellschaftsraum-so"
      cam={{
        orbitLat: -Math.PI * 0.04,
        orbitLong: -Math.PI * 0.5,
        zoom: 12,
      }}
    >
      <Light
        distance={8}
        intensity={0.5}
        color={new Color(0.9, 0.95, 0.8)}
        position={useVector3(0.8, 0.3, 0.08)}
      />
      <Highlight>
        <Button size={0.5} />
        <RootObject disableGlow />

        <HotspotTransition
          objectName="gesellschaftraumwand_1"
          rotation={useVector3(0, -1.3, 0)}
        />
        <HotspotTransition
          objectName="gesellschaftraumwand_2"
          rotation={useVector3(0, 1.3, 0)}
        />

        <EnableOnActiveOrParent>
          <Hotspot
            name="spieluhr-mit-orgel"
            cam={{
              orbitLat: -Math.PI * 0.04,
              orbitLong: -Math.PI * 0.5,
              zoom: 5,
            }}
          >
            <Highlight>
              <RootObject isButton />
            </Highlight>
          </Hotspot>

          <Hotspot
            name="hammerklavier"
            cam={{
              orbitLat: -Math.PI * 0.04,
              orbitLong: -Math.PI * 0.5,
              zoom: 5,
            }}
          >
            <Highlight>
              <RootObject isButton />
            </Highlight>
          </Hotspot>

          <Hotspot
            name="roentgen-tisch"
            objectName="roentgentisch"
            cam={{
              orbitLat: -Math.PI * 0.04,
              orbitLong: -Math.PI * 0.4,
              zoom: 5,
            }}
          >
            <Highlight>
              <RootObject isButton />
            </Highlight>
          </Hotspot>
        </EnableOnActiveOrParent>
      </Highlight>
    </Hotspot>
  );
}
