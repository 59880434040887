import React from "react";
import cn from "classnames";
import { Link } from "gatsby";

import { Logo } from "./logo";
import { usePageContext } from "../../gatsby/context";

export function Header(): JSX.Element {
  const { pagePath } = usePageContext();

  const isCatalogue = pagePath == "/katalog/";

  return (
    <header
      className={cn(
        "fixed inset-x-0 top-0 z-30 select-none h-16 pointer-events-none transition-all duration-500",
        isCatalogue && "bg-white bg-opacity-100"
      )}
    >
      <div className="flex h-full">
        <Link
          to="/"
          className={`inline-block px-4 py-2 transition duration-500 ${
            pagePath == "/" ? "opacity-0" : "pointer-events-auto"
          }`}
        >
          <Logo />
        </Link>
        <nav
          className={cn(
            "flex flex-1 px-4 justify-end items-center text-lg tracking-wider font-bold opacity-0 transition-all duration-500",
            isCatalogue && "pointer-events-auto opacity-100"
          )}
        >
          <Link to="/start/">Zum Schloss</Link>
        </nav>
      </div>
    </header>
  );
}
