import React from "react";

import { useHotspotScope } from "../../lib/hotspots";
import { Transition, TransitionProps } from "../../lib/animation";

import { useAudioClip } from "../../../../lib/audio";
import { useIsHighlighted } from "./highlight-hinter";
import { useGltf } from "../context";

function Audio({ active, src }: { active: boolean; src: string }) {
  const [lastActive, setLastActive] = React.useState(active);

  const clip = useAudioClip({ src });

  React.useEffect(() => {
    if (active === lastActive) return;
    if (active) clip.play();
    setLastActive(active);
  }, [active, lastActive, clip]);

  return null;
}

export function HotspotTransition({
  objectName,
  soundSrc,
  ...props
}: Omit<TransitionProps, "evolution" | "object"> & {
  objectName: string;
  soundSrc?: string;
}) {
  const scope = useHotspotScope();
  const gltf = useGltf();

  const isActiveOrParent = !!scope && (scope.active || scope.isParent);
  const active = isActiveOrParent;
  const isHighlighted = useIsHighlighted();

  return (
    <>
      {soundSrc && <Audio active={active} src={soundSrc} />}
      <Transition
        {...props}
        object={gltf!.nodes[objectName.replace("-", "_")]}
        evolution={active ? 1 : scope?.hovering || isHighlighted ? 0.333 : 0}
      />
    </>
  );
}
