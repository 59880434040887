import React from "react";

import MobileDetect from "mobile-detect";

const storageKey = "phone-unsupported-noticed";
const noticedStorage =
  typeof window != "undefined"
    ? window.localStorage.getItem(storageKey) != null
    : false;

export function MobileMessage() {
  const [isPhone, setIsPhone] = React.useState<boolean>(false);
  const [noticed, setNoticed] = React.useState(noticedStorage);

  React.useEffect(
    () =>
      setIsPhone(new MobileDetect(window.navigator.userAgent).phone() != null),
    []
  );

  return !isPhone || noticed ? null : (
    <div className="fixed inset-0 bg-white p-6 flex flex-col justify-center items-center z-50">
      <div className="max-w-md text-center">
        <div className="prose mb-8">
          <p>
            Diese Website kann leider nicht effektiv auf einem Handy genutzt
            werden. Bitte versuche es nochmal auf einem Computer.
          </p>
        </div>
        <button
          className="text-highlight font-bold border-2 border-highlight px-2 py-1"
          onClick={() => {
            window.localStorage.setItem(storageKey, "true");
            setNoticed(true);
          }}
        >
          Trotzdem versuchen
        </button>
      </div>
    </div>
  );
}
