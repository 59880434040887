import React from "react";
import { graphql, Link } from "gatsby";

import { AudioContextProvider } from "../lib/audio";
import { TooltipOverlay } from "../lib/tooltip-overlay";

import { Preloader } from "./preloader";
import { Header } from "./header";
import { UI } from "./ui";
import { ThreeContent, MainSceneContextProvider } from "./three";
import { IsReadyProvider } from "./ready-state";
import { CatalogueOverlay } from "./catalogue";
import { usePageContext } from "../gatsby";
import { MobileMessage } from "./mobile-message";
import { BgGradient } from "./bg-gradient";

export const query = graphql`
  fragment SpaData on Query {
    stateRoute: sitePage(path: { eq: $pagePath }) {
      componentChunkName
    }
  }
`;

export type SpaData = {
  stateRoute: {
    componentChunkName: string;
  };
};

export function SPA() {
  const { pagePath } = usePageContext();

  React.useEffect(() => {
    const oldValue = document.body.style.overflow;
    document.body.style.overflow = "hidden";
    return () => void (document.body.style.overflow = oldValue);
  }, []);

  return (
    <>
      <BgGradient />
      <MobileMessage />
      <CatalogueOverlay />
      <IsReadyProvider value={true}>
        <AudioContextProvider muted={pagePath == "/katalog/"}>
          <TooltipOverlay>
            <Preloader>
              <Header />
              <MainSceneContextProvider>
                <UI>
                  <ThreeContent />
                </UI>
              </MainSceneContextProvider>
            </Preloader>
          </TooltipOverlay>
        </AudioContextProvider>
      </IsReadyProvider>
      <Link
        to="/mitwirkende"
        className="z-10 fixed bottom-0 right-0 py-1.5 px-2.5 text-xs font-semibold block opacity-25 transition hover:opacity-100"
      >
        Mitwirkende
      </Link>
    </>
  );
}
