import React from "react";
import { Color } from "three";

import {
  EnableOnActiveOrParent,
  SelectionModifier,
} from "../../../../lib/hotspots";
import { useVector3 } from "../../../../lib/util";

import { HotspotButton as Button } from "../../button";
import { HotspotLight as Light } from "../../light";
import { Hotspot } from "../../hotspot";
import { Highlight } from "../highlight";
import { HotspotTransition } from "../../transition";
import { HotspotRootObject as RootObject } from "../../root-object";
import { ButtonSystem } from "../../button-system";

export function Room({ enabledIn }: { enabledIn: string[] }) {
  return (
    <Hotspot
      name="vestibuel"
      cam={{
        orbitLat: -0.2,
        orbitLong: 0,
        zoom: 16,
      }}
    >
      <Light
        distance={8}
        intensity={0.5}
        color={new Color(0.9, 0.95, 0.8)}
        position={useVector3(-0.07, 0.7, -0.5)}
      />
      <SelectionModifier names={enabledIn} affect="interactivity" additive>
        <Highlight>
          <Button size={0.38} />
          <RootObject disableGlow />

          <HotspotTransition
            objectName="vestibuelwand_l"
            position={useVector3(0, 3, 0)}
          />
          <HotspotTransition
            objectName="vestibuelwand_r"
            position={useVector3(0, 3, 0)}
          />

          <EnableOnActiveOrParent>
            <Hotspot
              name="verborgene-raeume"
              objectName="geheimtueren"
              cam={{
                orbitLat: 0,
                orbitLong: 0,
                zoom: 8,
              }}
            >
              <Highlight>
                <ButtonSystem size={0.08} />
                <RootObject />
              </Highlight>
            </Hotspot>

            <Hotspot
              name="elemente"
              cam={{
                orbitLat: 0,
                orbitLong: Math.PI / 4,
                zoom: 6,
              }}
            >
              <Highlight>
                <RootObject isButton />
              </Highlight>
            </Hotspot>

            <Hotspot
              name="vestibueluhr"
              cam={{
                orbitLat: 0,
                orbitLong: Math.PI / 10,
                zoom: 4,
              }}
            >
              <Highlight>
                <Button size={0.08} />
                <RootObject />
              </Highlight>
            </Hotspot>
          </EnableOnActiveOrParent>
        </Highlight>
      </SelectionModifier>
    </Hotspot>
  );
}
