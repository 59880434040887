import React from "react";
import { Color } from "three";

import {
  EnableOnActiveOrParent,
  SelectionModifier,
} from "../../../../lib/hotspots";
import { useVector3 } from "../../../../lib/util";

import { HotspotButton as Button } from "../../button";
import { HotspotLight as Light } from "../../light";
import { Hotspot } from "../../hotspot";
import { Highlight } from "../highlight";
import { HotspotTransition } from "../../transition";
import { HotspotRootObject as RootObject } from "../../root-object";
import { ButtonSystem } from "../../button-system";

export function Room() {
  return (
    <Hotspot
      name="schlafzimmer-ea"
      cam={{
        orbitLat: -Math.PI * 0.04,
        orbitLong: -Math.PI * 0.5,
        zoom: 12,
      }}
    >
      <Light
        distance={8}
        intensity={0.6}
        color={new Color(0.9, 0.95, 0.8)}
        position={useVector3(1, 0.75, 0)}
      />
      <SelectionModifier
        names="gartenkunstmuseum"
        affect="interactivity"
        additive
      >
        <Highlight>
          <Button size={0.5} />
          <RootObject disableGlow />

          <HotspotTransition
            objectName="schlafzimmerwand_1"
            rotation={useVector3(0, Math.PI * 0.75, 0)}
          />

          <HotspotTransition
            objectName="schlafzimmerwand_2"
            rotation={useVector3(0, -Math.PI * 0.75, 0)}
          />

          <EnableOnActiveOrParent>
            <Hotspot
              name="parkett"
              cam={{
                orbitLat: -Math.PI * 0.125,
                orbitLong: -Math.PI * 0.5,
                zoom: 9,
              }}
            >
              <Highlight>
                <RootObject isButton />
              </Highlight>
            </Hotspot>

            <Hotspot
              name="muschelbett"
              cam={{
                orbitLat: -Math.PI * 0.04,
                orbitLong: -Math.PI * 0.5,
                zoom: 4,
              }}
            >
              <Highlight>
                <RootObject isButton />
              </Highlight>
            </Hotspot>

            <Hotspot
              name="schminktisch"
              objectName="poudreuse"
              cam={{
                orbitLat: -Math.PI * 0.02,
                orbitLong: -Math.PI * 0.38,
                zoom: 3,
              }}
            >
              <Highlight>
                <RootObject isButton />
              </Highlight>
            </Hotspot>

            <Hotspot
              name="tapetentueren"
              cam={{
                orbitLat: -Math.PI * 0.04,
                orbitLong: -Math.PI * 0.7,
                zoom: 6,
              }}
            >
              <Highlight>
                <ButtonSystem size={0.06} />
                <RootObject />
              </Highlight>
            </Hotspot>
          </EnableOnActiveOrParent>
        </Highlight>
      </SelectionModifier>
    </Hotspot>
  );
}
