import React from "react";
import { ScopeModifier, ScopeModifierProps } from "./modifier";

import { useCurrentHotspot } from "../context";
import { HotspotScope, useHotspotScope } from "../scope";

type ParentModifierProps = Omit<ScopeModifierProps, "value">;

const ParentModifierCore: React.FC<
  ParentModifierProps & { scope: HotspotScope }
> = ({ scope: { name }, ...props }) => {
  const currentHotspot = useCurrentHotspot();

  const value = React.useMemo<boolean>(
    () => !!currentHotspot?.findChild(name),
    [name, currentHotspot]
  );

  return <ScopeModifier {...props} value={value} />;
};

export const ParentModifier: React.FC<ParentModifierProps> = (props) => {
  const scope = useHotspotScope();
  return scope ? (
    <ParentModifierCore {...props} scope={scope} />
  ) : (
    <>{props.children}</>
  );
};
