import React from "react";

import { Lighting } from "./lighting";
import { HotspotComposition } from "./hotspots";
import { BookOpenAnimation } from "./book-open-animation";
import { QualityChecker } from "./quality-check";
import { useIsReady } from "../../ready-state";
import { usePreloadInitialization } from "../../preloader";

import { useGltf } from "./context";

export * from "./context";

export function MainScene() {
  const isReady = useIsReady();
  const initCtx = usePreloadInitialization();

  const gltf = useGltf();

  return (
    <>
      {!isReady && initCtx && (
        <QualityChecker onQualityGood={initCtx.onAssetsInitialized} />
      )}
      <Lighting />
      {gltf && (
        <BookOpenAnimation gltf={gltf}>
          <HotspotComposition />
        </BookOpenAnimation>
      )}
    </>
  );
}
