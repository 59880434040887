import React from "react";
import { useTooltipContext } from "../../../lib/tooltip-overlay";
import { useIsReady } from "../../ready-state";

export type ProgressBarProps = {
  progress: number;
};

export function ProgressBar({ progress }: ProgressBarProps) {
  const isReady = useIsReady();

  const { registerTooltipElement } = useTooltipContext();
  const ref = React.useRef<SVGSVGElement>(null);
  React.useEffect(
    () =>
      registerTooltipElement({
        el: ref.current as Element,
        html: "<p>Sobald der Ladebalken voll ist, kannst du mit dem Erforschen beginnen.</p>",
      }),
    [registerTooltipElement]
  );

  return (
    <div className="relative z-10 w-full h-full flex flex-col justify-center items-center">
      <div
        className="bg-highlight-soft w-40 h-40 rounded-full transition ease-in duration-500"
        style={
          isReady ? { transform: "translateY(calc(50vh + 32rem))" } : undefined
        }
      >
        <svg
          className="relative text-highlight w-full h-full transform rotate-180 hover:cursor-none"
          viewBox="0 0 128 128"
          ref={ref}
        >
          <defs>
            <clipPath id="progress-bar-mask">
              <rect
                x="0"
                y="0"
                width="100%"
                height={`${Math.round(100 * (0.1 + 0.9 * progress))}%`}
              />
            </clipPath>
          </defs>
          <circle
            cx="50%"
            cy="50%"
            r="50%"
            stroke="none"
            fill="currentColor"
            clipPath="url(#progress-bar-mask)"
          />
        </svg>
      </div>
    </div>
  );
}
