import React from "react";

import { usePageContext } from "../../../../gatsby/context";
import { TreeNode, TreeNodeProps, TreeNodeObject } from "../../../../lib/tree";

export type HotspotData = Record<string, unknown>;

export type HotspotNodeObject<T extends HotspotData> = TreeNodeObject<T>;
export function HotspotNode<T extends HotspotData>(
  props: React.PropsWithChildren<TreeNodeProps<T>>
) {
  return <TreeNode<T> {...props} />;
}

type HotspotRecord<T extends HotspotData> = {
  [name in string]?: HotspotNodeObject<T>;
};

export type HotspotContext<T extends HotspotData = HotspotData> = {
  path?: string;
  hotspotRecord: HotspotRecord<T>;
  invisible: boolean;
  disabled: boolean;
  registerNode(node: HotspotNodeObject<T>): void | (() => void);
};

const Context = React.createContext<HotspotContext<HotspotData> | null>(null);

export const useHotspotContext = () => React.useContext(Context);
export function HotspotContextProvider<T extends HotspotData>({
  children,
  invisible,
  disabled,
}: React.PropsWithChildren<
  Partial<Pick<HotspotContext<T>, "invisible" | "disabled">>
>) {
  const [hotspotRecord, setHotspotRecord] = React.useState<HotspotRecord<T>>(
    {}
  );

  const recordRef = React.useRef<HotspotRecord<T>>({});
  const timeoutRef = React.useRef<NodeJS.Timeout | null>(null);
  const registerNode = React.useCallback((node: HotspotNodeObject<T>) => {
    if (!node.data) return;
    const { name } = node;
    recordRef.current[name] = node;

    const scheduleUpdate = () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() =>
        setHotspotRecord({ ...recordRef.current })
      );
    };

    scheduleUpdate();

    return () => {
      delete recordRef.current[name];
      scheduleUpdate();
    };
  }, []);

  return (
    <Context.Provider
      value={React.useMemo<HotspotContext<T>>(
        () => ({
          invisible: invisible || false,
          disabled: disabled || false,
          hotspotRecord,
          registerNode,
        }),
        [invisible, disabled, hotspotRecord, registerNode]
      )}
    >
      {children}
    </Context.Provider>
  );
}

export function useHotspot<T extends HotspotData>(name: string) {
  const ctx = useHotspotContext();
  return React.useMemo(
    () => ctx?.hotspotRecord[name] || null,
    [name, ctx]
  ) as HotspotNodeObject<T> | null;
}

export function useCurrentHotspot<T extends HotspotData>() {
  const { pagePath } = usePageContext();
  const ctx = useHotspotContext();
  return React.useMemo(
    () => ctx?.hotspotRecord[pagePath.split("/")[1]] || null,
    [pagePath, ctx]
  ) as HotspotNodeObject<T> | null;
}

export const HotspotContextAmplifier = Context.Provider;
