// extracted by mini-css-extract-plugin
export var frame = "card-module--frame--ilWw1";
export var bg = "card-module--bg--3PHz1";
export var text = "card-module--text--27vFK";
export var card = "card-module--card--3AJNl";
export var visible = "card-module--visible--2Wdhk";
export var heading = "card-module--heading--1Kx5F";
export var bordered = "card-module--bordered--10lUH";
export var vertLines = "card-module--vertLines--3iZ9Y";
export var mobile = "card-module--mobile--p1ieP";
export var pattern = "card-module--pattern--2Uk-A";
export var tabs = "card-module--tabs--1E1Rk";
export var tab = "card-module--tab--1yAJ4";
export var tabActive = "card-module--tabActive--1yk2Y";