// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-overlays-katalog-tsx": () => import("./../../../src/overlays/katalog.tsx" /* webpackChunkName: "component---src-overlays-katalog-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-mitwirkende-tsx": () => import("./../../../src/pages/mitwirkende.tsx" /* webpackChunkName: "component---src-pages-mitwirkende-tsx" */),
  "component---src-templates-hotspot-tsx": () => import("./../../../src/templates/hotspot.tsx" /* webpackChunkName: "component---src-templates-hotspot-tsx" */)
}

