import React from "react";
import { ScopeModifier, ScopeModifierProps } from "./modifier";

import { useCurrentHotspot } from "../context";

type SelectionModifierProps = Omit<ScopeModifierProps, "value"> & {
  names: string[] | string;
};

export const SelectionModifier: React.FC<SelectionModifierProps> = ({
  names,
  ...props
}) => {
  const currentHotspot = useCurrentHotspot();

  const value = React.useMemo<boolean>(() => {
    if (!currentHotspot) return true;
    const currentName = currentHotspot.name;
    return typeof names === "string"
      ? names === currentName
      : names.find((name) => name === currentName) !== undefined;
  }, [currentHotspot, names]);

  return <ScopeModifier {...props} value={value} />;
};
