import React from "react";

import { useHotspotPageData } from "../../../../templates/hotspot";
import {
  useTooltipContext,
  TooltipRegistration,
} from "../../../../lib/tooltip-overlay";

import * as styles from "./text.module.css";

export function CardText({ html }: { html: string }) {
  const { lexiconData } = useHotspotPageData()!;

  const ref = React.useRef<HTMLDivElement>(null);
  const { registerTooltipElement } = useTooltipContext()!;

  React.useEffect(() => {
    const recs = Array.from(ref.current!.querySelectorAll("span[data-lexicon]"))
      .map<[Element, string]>((el) => {
        const slug = el.getAttribute("data-lexicon");
        return [el, slug || ""];
      })
      .filter((rec) => !!rec[1]);

    const callbacks = recs
      .map<TooltipRegistration>(([el, slug]) => {
        const content = lexiconData.nodes.find(
          (node) => node.name == slug
        )?.childMarkdownRemark;

        return {
          el,
          html: content?.html || "",
          titleHtml: content?.frontmatter.title,
        };
      })
      .filter((reg) => !!reg.html)
      .map((reg) => {
        reg.el.classList.add(styles.tooltip);
        return registerTooltipElement(reg);
      });

    return () => callbacks.forEach((cb) => cb());
  }, [html, lexiconData, registerTooltipElement]);

  return (
    <div
      className={`${styles.el} prose 2xl:prose-lg py-4 px-6`}
      dangerouslySetInnerHTML={{
        __html: `${html}<div class="h-4"></div>`,
      }}
      ref={ref}
    />
  );
}
