import React from "react";
import { Link } from "gatsby";

import * as styles from "./major-link.module.css";

export const MajorLink: React.FC<{ to?: string }> = ({ children, to }) => {
  return to ? (
    <Link to={to} className={styles.el}>
      {children}
    </Link>
  ) : (
    <div className={styles.el}>{children}</div>
  );
};
