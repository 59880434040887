import React from "react";
import { GroupProps } from "@react-three/fiber";

import { useCursorStyle } from "../cursor-style";

export * from "./glow-on-hover";
export * from "./sphere-button";
export * from "./shaded-button";

export type ButtonProps = {
  onClick: () => void;
  disabled?: boolean;
  setHovering?(hovering: boolean): void;
};

export const Button: React.FC<ButtonProps> = ({
  children,
  onClick,
  disabled,
  setHovering: setParentHovering,
}) => {
  const [hovering, setHovering] = React.useState(false);

  useCursorStyle(hovering ? "pointer" : null);

  const updateHovering = React.useCallback(
    (value: boolean) => {
      setHovering(value);
      if (setParentHovering) setParentHovering(value);
    },
    [setParentHovering]
  );

  React.useEffect(() => {
    if (disabled) updateHovering(false);
  }, [disabled, updateHovering]);

  const handleClick = React.useCallback(
    (evt: MouseEvent) => {
      evt.stopPropagation();
      onClick();
    },
    [onClick]
  );

  const hoveringProps = React.useMemo<GroupProps>(
    () => ({
      onPointerOver: () => updateHovering(true),
      onPointerOut: () => updateHovering(false),
    }),
    [updateHovering]
  );

  const props: GroupProps | undefined = disabled
    ? undefined
    : {
        onClick: handleClick,
        ...hoveringProps,
      };

  return <group {...props}>{children}</group>;
};
