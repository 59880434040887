import React from "react";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import "swiper/css";
import "swiper/css/pagination";
import SwiperCore, { Pagination } from "swiper";
SwiperCore.use([Pagination]);
import "./swiper.css";
import { useCardVisibility } from "..";

type CardImageData = {
  kind: "image";
  data: IGatsbyImageData;
};

type CardVideoData = {
  kind: "video";
  src: string;
};

export type CardMediaData = CardImageData | CardVideoData;

let userHasInteracted = false;
if (typeof window != "undefined")
  window.addEventListener("click", () => void (userHasInteracted = true));

function CardVideo({ src, active }: { src: string; active: boolean }) {
  const videoRef = React.useRef<HTMLVideoElement>(null);
  const cardIsVisible = useCardVisibility();

  React.useEffect(() => {
    if (!userHasInteracted) return;
    const video = videoRef.current!;
    if (active && cardIsVisible) video.play();
    else video.pause();
  }, [active, cardIsVisible]);

  return (
    <div className="relative pt-9/16 bg-card-border">
      <video className="absolute inset-0" controls playsInline ref={videoRef}>
        <source src={src} type="video/mp4" />
      </video>
    </div>
  );
}

export function CardMedia({
  data,
  invisible,
}: {
  data: CardMediaData;
  invisible?: boolean;
}) {
  return data.kind == "image" ? (
    <GatsbyImage image={data.data} alt="" draggable={false} />
  ) : (
    <CardVideo src={data.src} active={!invisible} />
  );
}
