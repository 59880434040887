import React from "react";
import { Object3D, Vector3, Vector3Tuple } from "three";
import { useSpring, animated } from "@react-spring/three";
import { useFrame } from "@react-three/fiber";

import { GLTF, useGLTFTransition } from "../../lib/gltf";
import { usePageContext } from "../../../../gatsby/context";
import { HotspotContextAmplifier, useHotspotContext } from "../../lib/hotspots";
import { useAudioClip, useAmbientSound } from "../../../../lib/audio";
import { Link } from "../../link";

import soundSrc from "./sound.mp3";
import ambientSrc from "./ambient.mp3";

import { useIsReady } from "../../../ready-state";
import { GltfObject } from "../context";

const Context = React.createContext(false);

const springPrecision = 0.0005;
const bookHalfWidth = 12;

export const useBookIsOpen = () => React.useContext(Context);

const v = new Vector3();
const Wiggler: React.FC<{ active: boolean; timeScale?: number; amp?: number }> =
  ({ children, active, timeScale = 1, amp = 0.1 }) => {
    const ref = React.useRef<Object3D>(null);
    const timeScaleRef = React.useRef(timeScale);

    React.useEffect(() => {}, []);
    const ampSwitch = active ? 1 : 0;
    const ampRef = React.useRef(ampSwitch);
    useSpring({
      amp: ampSwitch,
      onChange: (data) => void (ampRef.current = data.value.amp),
    });

    const timeRef = React.useRef(0);
    useFrame((_, delta) => {
      const group = ref.current;
      if (!group || (!v.z && !ampRef.current)) return;

      timeScaleRef.current += 0.06 * (timeScale - timeScaleRef.current);
      timeRef.current += timeScaleRef.current * delta;
      v.setZ(
        ampRef.current * amp * Math.PI * Math.sin(timeRef.current * Math.PI)
      );
      group.rotation.setFromVector3(v);
    });

    return <group ref={ref}>{children}</group>;
  };

const LayoutShift: React.FC<{ isOpen: boolean }> = ({ children, isOpen }) => {
  const { x } = useSpring({
    x: isOpen ? 0 : -20,
    config: { tension: 38, precision: springPrecision },
  });

  return <animated.group position-x={x}>{children}</animated.group>;
};

export const BookOpenAnimation: React.FC<{ gltf: GLTF }> = ({
  children,
  gltf,
}) => {
  const appReady = useIsReady();
  const hotspotCtx = useHotspotContext();

  const { pagePath } = usePageContext();
  const [pathState, setPathState] = React.useState(pagePath);
  React.useEffect(() => setPathState(pagePath), [pagePath]);

  const isOpenGoal = pagePath !== "/";
  const isOpenInitial = React.useRef(isOpenGoal);
  const [isOpen, setIsOpen] = React.useState(isOpenInitial.current);

  const inProgress = useGLTFTransition(
    gltf,
    "aufklappanimation_buch",
    pagePath !== "/",
    isOpenInitial.current
  );

  React.useEffect(
    () => setIsOpen(pathState !== "/" && !inProgress),
    [pathState, inProgress]
  );

  const clip = useAudioClip({ src: soundSrc });
  React.useEffect(() => {
    if (pagePath !== pathState && (pagePath === "/" || pathState === "/"))
      clip.play();
  }, [pagePath, pathState, clip]);

  useAmbientSound({ src: ambientSrc, muted: !isOpenGoal || inProgress });

  const [landingFinished, setLandingFinished] = React.useState(appReady);
  React.useEffect(() => {
    if (!appReady) {
      setLandingFinished(false);
      return;
    }

    const timeout = setTimeout(() => setLandingFinished(true), 2000);
    return () => clearTimeout(timeout);
  }, [appReady]);

  const { rotationZ, position } = useSpring({
    rotationZ: isOpenGoal ? 0 : appReady ? 0 : -Math.PI / 2,
    position: (isOpenGoal
      ? [0, 0, 0]
      : [-10.8, 0, appReady ? 0 : -50]) as Vector3Tuple,
    config: { tension: 28, precision: springPrecision },
  });

  const [hovering, setHovering] = React.useState(false);

  const interactive = !isOpenGoal && !inProgress && landingFinished;

  return (
    <LayoutShift isOpen={isOpenGoal}>
      <Link to="/start/" setHovering={setHovering} disabled={!interactive}>
        <mesh scale={[25, 3, 33]}>
          <boxGeometry />
          <meshBasicMaterial opacity={0} transparent />
        </mesh>
      </Link>
      <Context.Provider value={isOpen}>
        <HotspotContextAmplifier
          value={React.useMemo(
            () =>
              hotspotCtx && {
                ...hotspotCtx,
                invisible: hotspotCtx.invisible || !isOpen,
              },
            [hotspotCtx, isOpen]
          )}
        >
          <animated.group rotation-z={rotationZ} position={position}>
            <group position-x={bookHalfWidth}>
              <Wiggler
                active={interactive}
                amp={0.05}
                timeScale={hovering ? 2 : 0.5}
              >
                <group position-x={-bookHalfWidth}>
                  <GltfObject name="buch" />
                </group>
              </Wiggler>
            </group>
            {children}
          </animated.group>
        </HotspotContextAmplifier>
      </Context.Provider>
    </LayoutShift>
  );
};
