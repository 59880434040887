import React from "react";
import cn from "classnames";

import { CardSection } from "./section";
import * as styles from "./card.module.css";
import { HotspotPageData } from "../../../templates/hotspot";
import { useCardTab } from "./tabs";

const FooterCell: React.FC<{ className?: string }> = ({
  children,
  className,
}) => (
  <div
    className={cn(
      styles.bordered,
      className,
      "inline-block bg-card-bg text-card-border font-bold line-clamp-1"
    )}
  >
    {children}
  </div>
);

export function CardFooter({
  data,
}: {
  data: HotspotPageData["file"]["childHotspot"];
}) {
  const tabCtrl = useCardTab();

  const tabData = tabCtrl?.current.childMarkdownRemark.frontmatter;

  const location = tabData?.location || data.location;
  const meta1 = tabData?.meta1 || data.meta1;

  return (
    <footer className={styles.pattern}>
      <CardSection
        mid={
          location ? (
            <div className="px-6 py-2">
              <FooterCell className="px-2 py-1">{location}</FooterCell>
            </div>
          ) : (
            <span>&nbsp;</span>
          )
        }
        right={
          meta1 ? (
            <div className="px-4 py-2">
              <FooterCell className="text-center py-1">
                <span className="text-sm">{meta1}</span>
              </FooterCell>
            </div>
          ) : undefined
        }
      />
    </footer>
  );
}
