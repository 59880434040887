import React from "react";

import { ContextModifier, ContextModifierProps } from "./modifier";
import { useHotspotScope } from "../scope";

type OnActiveOrParentProps = Omit<ContextModifierProps, "value">;

export const OnActiveOrParent: React.FC<OnActiveOrParentProps> = (props) => {
  const scope = useHotspotScope();
  return scope ? (
    <ContextModifier {...props} value={scope.isParent || scope.active} />
  ) : (
    <>{props.children}</>
  );
};

export const EnableOnActiveOrParent: React.FC<
  Omit<OnActiveOrParentProps, "affect">
> = (props) => <OnActiveOrParent {...props} affect="interactivity" />;
