import React from "react";
import { Light, Vector3 } from "three";
import { PointLightProps } from "@react-three/fiber";
import { useSpring, config } from "@react-spring/three";

import { useHotspotScope } from "./hotspot";

export type HotspotLightProps = Pick<
  PointLightProps,
  "intensity" | "color" | "distance"
> & {
  position?: Vector3;
  relativeToPivot?: boolean;
};

export function HotspotLight({
  position,
  relativeToPivot,
  intensity,
  ...props
}: HotspotLightProps) {
  const scope = useHotspotScope();
  const ref = React.useRef<Light>(null);

  const intensityGoal = (scope?.visible && intensity) || 0;
  const initialIntensity = React.useRef(intensityGoal);

  const updateIntensity = React.useCallback(
    (value: number) => void (ref.current && (ref.current.intensity = value)),
    []
  );

  React.useEffect(
    () => updateIntensity(initialIntensity.current),
    [updateIntensity]
  );

  useSpring({
    value: intensityGoal,
    onChange: (value) => updateIntensity(value.value.value),
    config: config.slow,
  });

  return (
    scope && (
      <group
        position={
          relativeToPivot
            ? scope.data.origin || new Vector3()
            : scope.data.focusPoint
        }
      >
        <group position={position}>
          <pointLight {...props} ref={ref} />
        </group>
      </group>
    )
  );
}
