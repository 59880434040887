import React from "react";
import { Object3D } from "three";

import { useHotspotObjectName, useHotspotScope } from "./hotspot";
import { GlowEffect } from "./glow";
import { useGltf } from "../context";

export function HotspotMesh({
  nodeName,
  transparent,
}: {
  nodeName?: string;
  transparent?: boolean;
}) {
  const gltf = useGltf()!;
  const scope = useHotspotScope();
  const objectName = useHotspotObjectName();
  const meshName =
    nodeName || `${objectName}_${transparent ? "buttonmesh" : "mesh"}`;
  const node = (gltf.nodes as { [name in string]?: Object3D })[meshName];

  if (!node) throw Error(`Object not found: ${meshName}`);

  return <GlowEffect object={node} active={!!scope?.hovering} />;
}
