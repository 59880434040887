import React from "react";
import { CSSTransition } from "react-transition-group";

import { ProgressBar, ProgressBarProps } from "./progress-bar";
import logoSrc from "../../../assets/logo.svg";
import { MajorLink } from "../../major-link";
import { useIsReady } from "../../ready-state";

import * as styles from "./transition.module.css";
import { TooltipTarget } from "../../../lib/tooltip-overlay";

function useHasMounted() {
  const [hasMounted, setHasMounted] = React.useState(false);
  React.useEffect(() => setHasMounted(true), []);
  return hasMounted;
}

export function LandingLoadingScreen({
  active,
  ...props
}: ProgressBarProps & { active: boolean }) {
  const isReady = useIsReady();
  const hasMounted = useHasMounted();

  const startLink = (
    <MajorLink to={isReady ? "/start/" : undefined}>Buch öffnen</MajorLink>
  );

  return (
    <div className="relative z-10 h-full">
      <div className="h-full px-10 py-16 sm:p-0 sm:grid grid-cols-2">
        <div className="flex justify-end px-8">
          <div className="max-w-xl w-full">
            <ProgressBar {...props} />
          </div>
        </div>
        <div className="overflow-auto pointer-events-auto">
          <div className="h-full overflow-x-hidden">
            <div className="max-w-xl h-full flex items-center  pl-4 pr-8">
              <div className="py-16">
                <CSSTransition
                  in={hasMounted && active}
                  classNames={styles}
                  timeout={3000}
                >
                  <div className={styles.enter}>
                    <div className="w-40 select-none">
                      <img
                        src={logoSrc}
                        className="w-full h-auto"
                        draggable={false}
                      />
                    </div>
                    <div className="prose text-text select-auto my-10">
                      <h3>Willkommen auf Schloss Benrath!</h3>
                      <p>
                        Die Sommerresidenz von Carl Theodor und Elisabeth
                        Augusta ist schon über 260 Jahre alt. Hier ist viel
                        passiert! Tauche ein in die luxuriöse Welt des Schlosses
                        und gehe mit unserem Buch auf Entdeckertour. Kannst du
                        die wichtigsten Gegenstände, Tiere und Pflanzen finden?
                        Sie leuchten kurz auf. Klicke sie einfach an, um mehr
                        über ihre Geschichte zu erfahren. Im Katalog findest du
                        noch mal alle Objekte auf einen Blick. Viel Spaß!
                      </p>
                    </div>
                    <div>
                      <div className="mb-1.5">
                        {isReady ? (
                          startLink
                        ) : (
                          <TooltipTarget
                            className="inline-block"
                            html="<p>Sobald der Ladeprozess beendet ist, kann es losgehen.</p>"
                          >
                            {startLink}
                          </TooltipTarget>
                        )}
                      </div>
                      <MajorLink to="/katalog/">Zum Katalog</MajorLink>
                    </div>
                  </div>
                </CSSTransition>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
