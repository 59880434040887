import React from "react";
import { isMesh, isSkinnedMesh, GLTF } from ".";
import { Material, MeshStandardMaterial } from "three";

export function isStandardMaterial(
  material: Material
): material is MeshStandardMaterial {
  return material.type === "MeshStandardMaterial";
}

function sanitizeMaterial(material: Material) {
  if (material.transparent) material.alphaTest = 0.95;
}

export function useGLTFSanitization(gltf: GLTF | null) {
  React.useMemo(() => {
    if (!gltf) return;

    Object.keys(gltf.nodes).forEach((nodeName) => {
      const node = gltf.nodes[nodeName];
      if (!isMesh(node)) return;
      node.castShadow = true;
      node.receiveShadow = true;

      if (isSkinnedMesh(node)) node.frustumCulled = false;

      const { material } = node;
      if (Array.isArray(material)) material.forEach(sanitizeMaterial);
      else sanitizeMaterial(material);
    });
  }, [gltf]);
}
