import { graphql } from "gatsby";
import React from "react";
import { IGatsbyImageData } from "gatsby-plugin-image";
import { PageComponent, usePageContext, usePageData } from "../gatsby";

import { SEO } from "../components/seo";

export const query = graphql`
  query ($pagePath: String!, $lexiconSlugs: [String]) {
    ...SpaData
    file(fields: { pagePath: { eq: $pagePath } }) {
      childHotspot {
        title
        description
        content {
          childMarkdownRemark {
            excerpt(format: PLAIN)
            html
            frontmatter {
              title
              short_title
              location
              meta1
              images {
                extension
                publicURL
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
            }
          }
        }
        feature_image {
          childImageSharp {
            resize(fit: COVER, width: 1200, height: 630) {
              src
              width
              height
            }
          }
        }
        location
        type
        meta1
      }
    }

    lexiconData: allFile(
      filter: {
        sourceInstanceName: { eq: "lexicon" }
        name: { in: $lexiconSlugs }
      }
    ) {
      nodes {
        name
        childMarkdownRemark {
          frontmatter {
            title
          }
          html
        }
      }
    }
  }
`;

export enum HotspotType {
  Flora = "flora",
  Fauna = "fauna",
  Exhibit = "exhibit",
}

export type LexiconData = {
  nodes: Array<{
    name: string;
    childMarkdownRemark: {
      frontmatter: { title?: string };
      html: string;
    };
  }>;
};

export type HotspotContentData = {
  childMarkdownRemark: {
    excerpt: string;
    html: string;
    frontmatter: {
      title?: string;
      short_title?: string;
      images?: {
        extension: string;
        publicURL: string;
        childImageSharp?: { gatsbyImageData: IGatsbyImageData };
      }[];
      location?: string;
      meta1?: string;
    };
  };
};

export type HotspotPageData = {
  file: {
    childHotspot: {
      title?: string;
      description?: string;
      content?: Array<HotspotContentData>;
      feature_image?: {
        childImageSharp: {
          resize: { src: string; width: number; height: number };
        };
      };
      location?: string;
      type?: HotspotType;
      meta1?: string;
    };
  };
  lexiconData: LexiconData;
};

export function useHotspotPageData(): HotspotPageData | undefined {
  const data = usePageData();

  return React.useMemo(() => {
    if (data && (data as HotspotPageData).file?.childHotspot)
      return data as HotspotPageData;
    return;
  }, [data]);
}

const Template: PageComponent<HotspotPageData> = ({ data }) => {
  const { cardTabIndex } = usePageContext();
  const { title, description, feature_image, content } = data.file.childHotspot;

  return (
    <SEO
      title={title}
      description={
        description ||
        content?.[cardTabIndex || 0].childMarkdownRemark.excerpt ||
        ""
      }
      image={feature_image ? feature_image.childImageSharp.resize : undefined}
    />
  );
};

export default Template;
