import React from "react";
import { Howl } from "howler";

import { useAudioContext } from "./context";

export type AudioClipProps = {
  src: string;
  loop?: boolean;
  muted?: boolean;
};

export function useAudioClip({
  src,
  loop,
  muted: mutedProp,
}: AudioClipProps): Howl {
  const { muted } = useAudioContext();

  const mutedRef = React.useRef(false);
  mutedRef.current = muted || mutedProp || false;

  const howl = React.useMemo(
    () =>
      new Howl({
        src,
        loop,
        volume: mutedRef.current ? 0 : 1,
      }),
    [src, loop]
  );

  React.useEffect(() => {
    const prevHowl = howl;
    return () => void prevHowl.stop();
  }, [howl]);

  React.useEffect(() => {
    if (!howl) return;
    const volume = howl.volume();
    const targetVolume = muted || mutedProp ? 0 : 1;
    if (volume !== targetVolume) howl.fade(volume, targetVolume, 700);
  }, [howl, muted, mutedProp]);

  return howl;
}
