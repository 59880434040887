import React from "react";
import { graphql } from "gatsby";

import { usePageData, PageComponent } from "../gatsby";
import { SEO } from "../components/seo";

export const query = graphql`
  fragment CatalogueThumbnailFragment on File {
    childImageSharp {
      resize(width: 512, height: 512, fit: INSIDE, toFormat: NO_CHANGE) {
        src
        width
        height
      }
    }
  }

  query {
    catalogueData: allFile(
      filter: {
        sourceInstanceName: { eq: "hotspot" }
        extension: { eq: "yml" }
        childHotspot: { type: { ne: null } }
      }
    ) {
      nodes {
        fields {
          pagePath
        }
        childHotspot {
          title
          location
          content {
            childMarkdownRemark {
              excerpt(format: PLAIN, pruneLength: 200)
              frontmatter {
                title
                thumbnail {
                  ...CatalogueThumbnailFragment
                }
                title_in_catalogue
                hide_in_catalogue
              }
            }
          }
          type
          thumbnail {
            ...CatalogueThumbnailFragment
          }
        }
      }
    }
  }
`;

export type CatalogueItemImageData = {
  childImageSharp: {
    resize: { src: string; width: number; height: number };
  };
};
export type CataloguePageData = {
  catalogueData: {
    nodes: Array<{
      fields: { pagePath: string };
      childHotspot: {
        title: string;
        location: string;
        thumbnail?: CatalogueItemImageData;
        content?: Array<{
          childMarkdownRemark: {
            excerpt: string;
            frontmatter: {
              title: string;
              thumbnail?: CatalogueItemImageData;
              title_in_catalogue?: string;
              hide_in_catalogue?: boolean;
            };
          };
        }>;
        type: string;
      };
    }>;
  };
};

export function useCataloguePageData(): CataloguePageData | undefined {
  const data = usePageData();

  return React.useMemo(() => {
    if (!data || !(data as CataloguePageData).catalogueData) return;
    return data as CataloguePageData;
  }, [data]);
}

const Page: PageComponent = () => {
  return (
    <SEO
      title="Katalog"
      description="Alle Exponate des Schlosses auf einer Seite"
    />
  );
};

export default Page;
