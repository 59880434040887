import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { useLocation } from "@reach/router";

import defaultImageURL from "./image.jpg";

export type SiteMetadata = {
  title: string;
  author: string;
  image?: { width: number; height: number; src: string };
  siteUrl: string;
};

type MetaTagProps = JSX.IntrinsicElements["meta"];

type Props = Partial<Omit<SiteMetadata, "siteUrl" | "lang">> & {
  description: string;
  titleSuffix?: string;
  meta?: MetaTagProps[];
};

export const SEO: React.FC<Props> = (pageProps) => {
  const siteProps = useStaticQuery<{ site: { siteMetadata: SiteMetadata } }>(
    graphql`
      query {
        site {
          siteMetadata {
            title
            author
            siteUrl
          }
        }
      }
    `
  ).site.siteMetadata;
  const { pathname } = useLocation();

  const props: Props = {
    ...siteProps,
    ...pageProps,
  };

  const title = `${pageProps.title ? `${pageProps.title} | ` : ""}${
    siteProps.title
  }${pageProps.titleSuffix ? ` — ${pageProps.titleSuffix}` : ""}`;

  const { description, author, meta } = props;
  let { image } = props;

  const defaultMeta: MetaTagProps[] = [
    { name: `description`, content: description },
    { property: "og:title", content: title },
    { property: "og:description", content: description },
    { property: "og:type", content: "website" },
    { property: "og:url", content: `${siteProps.siteUrl}${pathname}` },
    { name: "twitter:card", content: "summary" },
    { name: "twitter:creator", content: author },
    { name: "twitter:title", content: title },
    { name: "twitter:description", content: description },
  ];

  if (!image)
    image = {
      src: defaultImageURL,
      width: 1200,
      height: 630,
    };

  defaultMeta.push(
    {
      property: "og:image:width",
      content: String(image.width),
    },
    {
      property: "og:image:height",
      content: String(image.height),
    },
    {
      property: "og:image",
      content: `${siteProps.siteUrl}${image.src}`,
    }
  );

  return (
    <Helmet
      htmlAttributes={{ lang: "de" }}
      title={title}
      meta={meta ? [...defaultMeta, ...meta] : defaultMeta}
    />
  );
};
