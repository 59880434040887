import React from "react";
import { Camera as CameraType, Object3D, Vector3Tuple } from "three";
import { PerspectiveCamera } from "@react-three/drei";
import { useSpring, animated } from "@react-spring/three";

import { Gimbal } from "./gimbal";
import { useCamAudio } from "./audio";

import { useCurrentHotspot, HotspotNodeObject } from "../scene/hotspots";
import { useIsReady } from "../../ready-state";
import { usePageContext } from "../../../gatsby/context";
import { useCardVisibility } from "../../ui/card";

type CameraState = {
  pivotPosition: Vector3Tuple;
  pivotLat: number;
  pivotLong: number;
  zoom: number;
};

const pi2 = 2 * Math.PI;
function useCamState(
  hotspot: HotspotNodeObject,
  turntableRef: React.RefObject<Object3D>
): CameraState {
  return React.useMemo(() => {
    if (!hotspot?.data) throw Error();

    const { focusPoint, cam } = hotspot.data;

    let pivotLong = cam.orbitLong;

    const turntable = turntableRef.current;
    if (turntable) {
      const rot = turntable.rotation.toVector3().y;

      let diff = pivotLong - rot;
      let absDiff = Math.abs(diff);
      if (absDiff > pi2) {
        pivotLong += (diff > 0 ? -pi2 : pi2) * Math.ceil(absDiff / pi2);
      }

      diff = pivotLong - rot;
      absDiff = Math.abs(diff);
      if (absDiff > Math.PI) {
        pivotLong += diff > 0 ? -pi2 : pi2;
      }
    }

    return {
      pivotPosition: focusPoint.toArray(),
      pivotLat: cam.orbitLat,
      pivotLong,
      zoom: cam.zoom,
    };
  }, [hotspot, turntableRef]);
}

const springPrecision = 0.0005;

function CameraCore({ currentHotspot }: { currentHotspot: HotspotNodeObject }) {
  const dollyActive = useCardVisibility();
  const appIsReady = useIsReady();
  const camRef = React.useRef<CameraType | null>(null);
  const turntableRef = React.useRef<Object3D>(null);
  const routeCamState = useCamState(currentHotspot, turntableRef);
  useCamAudio({ camRef });
  const { pagePath } = usePageContext();

  const state = routeCamState;

  const { pivotLong, ...restState } = state;

  const spring = useSpring({
    ...restState,
    zoom: restState.zoom * (appIsReady ? 1 : 1.5),
    config: { tension: 25, precision: springPrecision },
  });
  const slowSpring = useSpring({
    pivotLong,
    dolly: dollyActive ? 0.1 * restState.zoom : 0,
    config: { tension: 19, precision: springPrecision },
  });

  const [fov, setFov] = React.useState(22);
  React.useEffect(() => {
    function updateFov() {
      setFov(window.innerWidth / window.innerHeight > 1 ? 22 : 30);
    }

    updateFov();

    window.addEventListener("resize", updateFov);
    return () => window.removeEventListener("resize", updateFov);
  }, []);

  return (
    <animated.group
      position={spring.pivotPosition}
      rotation-y={slowSpring.pivotLong}
      ref={turntableRef}
    >
      <Gimbal disabled={pagePath === "/" || pagePath == "/uebersicht/"}>
        <animated.group rotation-x={spring.pivotLat}>
          <animated.group
            position-z={spring.zoom}
            position-x={slowSpring.dolly}
          >
            <PerspectiveCamera
              makeDefault
              fov={fov}
              near={1}
              far={1000}
              ref={camRef}
            />
          </animated.group>
        </animated.group>
      </Gimbal>
    </animated.group>
  );
}

export function Camera() {
  const hotspot = useCurrentHotspot();

  return hotspot && <CameraCore currentHotspot={hotspot} />;
}
