import React from "react";
import isTouchDevice from "is-touch-device";

import { CardSection } from "../section";
import * as styles from "../card.module.css";
import { HotspotContentData } from "../../../../templates/hotspot";

import { CardMedia, CardMediaData } from "./media";
import { CardSwiper } from "./swiper";
import { CardText } from "./text";

function SplashMedia({ data }: { data: CardMediaData[] }) {
  return (
    <div className="p-6 border-b-2 border-card-border">
      {data.length > 1 ? (
        <CardSwiper data={data} />
      ) : (
        <CardMedia data={data[0]} />
      )}
    </div>
  );
}

export function CardContent({
  html,
  frontmatter,
}: HotspotContentData["childMarkdownRemark"]) {
  const data = React.useMemo<CardMediaData[] | null>(
    () =>
      frontmatter?.images?.map<CardMediaData>((file) =>
        file.extension == "mp4"
          ? {
              kind: "video",
              src: file.publicURL,
            }
          : {
              kind: "image",
              data: file.childImageSharp!.gatsbyImageData,
            }
      ) || null,
    [frontmatter]
  );

  const containerRef = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    containerRef.current!.scrollTo(0, 0);
  }, [data]);

  React.useEffect(() => {
    if (isTouchDevice()) return;
    const div = containerRef.current!;
    const observer = new ResizeObserver(([entry]) => {
      const hasScrollbar = div.scrollHeight > entry.contentRect.height;
      div.style.width = hasScrollbar ? "calc(100% + 16px)" : "";
    });
    observer.observe(div);
    return () => observer.disconnect();
  }, []);

  return (
    <main className="flex-1 overflow-hidden">
      <CardSection
        hidePaddingOnMobile
        className={`${styles.vertLines} h-full`}
        mid={
          <div className="h-full overflow-y-auto" ref={containerRef}>
            {data && <SplashMedia data={data} />}
            <CardText html={html} />
          </div>
        }
      />
    </main>
  );
}
