import React from "react";
import { useGltf } from "../context";
import { useHotspotObjectName } from "./hotspot";
import { HotspotButton } from "./button";

export function ButtonSystem({ size }: { size?: number }) {
  const objectName = useHotspotObjectName();
  const gltf = useGltf()!;

  const positions = React.useMemo(() => {
    const regexp = new RegExp(`^${objectName}_center_button\\d+$`);
    return Object.keys(gltf.nodes)
      .filter((name) => name.match(regexp))
      .map((name) => {
        const node = gltf.nodes[name];
        return gltf.nodes[name].position
          .clone()
          .applyQuaternion(node.parent!.quaternion);
      });
  }, [objectName, gltf]);

  return (
    <>
      {positions.map((pos, i) => (
        <HotspotButton
          key={i}
          size={size || 0.1}
          position={pos}
          relativeToPivot
        />
      ))}
    </>
  );
}
