import React from "react";

import { IsReadyProvider, useIsReady } from "../ready-state";
import { Preloader as PreloaderBase } from "../../lib/preloader";
import { LoadingScreen } from "./loading-screen";
import { preloadAssets } from "../three";
import {
  PreloadProvider,
  PreloadResult,
  usePreloadResult,
} from "../../lib/preloader/context";

type InitializationContext = { onAssetsInitialized(): void };

const Context = React.createContext<InitializationContext | null>(null);

export const usePreloadInitialization = () => React.useContext(Context);

export const Preloader: React.FC = ({ children }) => {
  const parentIsReady = useIsReady();
  const [progress, setProgress] = React.useState(0);
  const [initialized, setInitialized] = React.useState(false);
  const doneLoading = progress >= 1;

  const initCtx = React.useMemo<InitializationContext | null>(
    () =>
      doneLoading ? { onAssetsInitialized: () => setInitialized(true) } : null,
    [doneLoading]
  );

  return (
    <PreloaderBase assets={preloadAssets} setProgress={setProgress}>
      <IsReadyProvider value={parentIsReady && initialized}>
        <LoadingScreen progress={progress} />
        <Context.Provider value={initCtx}>{children}</Context.Provider>
      </IsReadyProvider>
    </PreloaderBase>
  );
};

type PreloaderContext = {
  result: PreloadResult | null;
  initialization: InitializationContext | null;
};

export function usePreloaderContext() {
  const initialization = usePreloadInitialization();
  const result = usePreloadResult();

  return React.useMemo<PreloaderContext>(
    () => ({ result, initialization }),
    [result, initialization]
  );
}

export const PreloaderAmplifier: React.FC<{ value: PreloaderContext }> = ({
  children,
  value: { result, initialization },
}) => (
  <PreloadProvider value={result}>
    <Context.Provider value={initialization}>{children}</Context.Provider>
  </PreloadProvider>
);
