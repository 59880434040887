import React from "react";

import { useAudioClip } from "./clip";

export function useAmbientSound({
  src,
  muted,
}: {
  src: string;
  muted?: boolean;
}) {
  const [innerMuted, setInnerMuted] = React.useState(true);

  const howl = useAudioClip({
    src,
    loop: true,
    muted: innerMuted || muted,
  });

  React.useEffect(() => {
    howl.play();
    setInnerMuted(false);
  }, [howl]);
}
