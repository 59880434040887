import React from "react";

import type { GLTF } from ".";

export type GltfContext = {
  gltf: GLTF;
  scaleFactor: number;
};

export function createGltfContext() {
  const Context = React.createContext<GltfContext | null>(null);

  const useGltfContext = () => React.useContext(Context);
  const useGltf = () => React.useContext(Context)?.gltf || null;

  function GltfObject({
    name,
    renderOrder,
  }: {
    name: string;
    renderOrder?: number;
  }) {
    const ctx = useGltfContext();
    const object = ctx?.gltf.nodes[name] || null;

    React.useEffect(() => {
      if (!object || renderOrder == undefined) return;
      object.renderOrder = renderOrder;
    }, [object, renderOrder]);

    return (
      ctx &&
      object && (
        <group scale={[ctx.scaleFactor, ctx.scaleFactor, ctx.scaleFactor]}>
          <primitive object={object} />
        </group>
      )
    );
  }

  return {
    GltfContextProvider: Context.Provider,
    useGltfContext,
    useGltf,
    GltfObject,
  };
}
