import React from "react";
import { Link } from "gatsby";
import cn from "classnames";

import {
  HotspotPageData,
  HotspotContentData,
} from "../../../templates/hotspot";
import * as styles from "./card.module.css";
import { usePageContext } from "../../../gatsby";

type CardTabController = {
  current: HotspotContentData;
  index: number;
  tabs: HotspotContentData[];
};
const CardTabContext = React.createContext<CardTabController | null>(null);
export const useCardTab = () => React.useContext(CardTabContext);

export const CardTabProvider: React.FC<{ data: HotspotPageData | null }> = ({
  children,
  data,
}) => {
  const { cardTabIndex } = usePageContext();

  const value = React.useMemo<CardTabController | null>(() => {
    const tabs = data?.file.childHotspot.content;
    if (!tabs) return null;
    const index = cardTabIndex || 0;
    return { index, current: tabs[index], tabs };
  }, [data, cardTabIndex]);

  return value ? (
    <CardTabContext.Provider value={value}>{children}</CardTabContext.Provider>
  ) : (
    <>{children}</>
  );
};

export const CardTabs = ({ data }: { data?: HotspotPageData | null }) => {
  const tabCtrl = useCardTab();
  const { pagePath } = usePageContext();

  return (
    <div className={styles.tabs}>
      {tabCtrl &&
        data &&
        Array.from(tabCtrl.tabs)
          .reverse()
          .map((tabData, i) => {
            const { current, tabs } = tabCtrl;
            const id = tabs.length - 1 - i;

            const { title, short_title } =
              tabData.childMarkdownRemark.frontmatter;

            return (
              <Link
                to={id ? `${pagePath}${id + 1}/` : pagePath}
                className={cn(
                  styles.tab,
                  styles.pattern,
                  styles.bordered,
                  "w-full p-3",
                  tabData == current && styles.tabActive
                )}
                key={i}
              >
                <div
                  className={cn(
                    styles.bordered,
                    styles.bg,
                    styles.text,
                    "font-bold px-2 py-0.5 line-clamp-1 text-center"
                  )}
                >
                  {short_title || title || data.file.childHotspot.title}
                </div>
              </Link>
            );
          })}
    </div>
  );
};
