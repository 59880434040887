import React from "react";

import { Button, ButtonProps } from ".";

export function SphereButton({
  renderOrder = 10,
  ...props
}: ButtonProps & { renderOrder?: number }) {
  return (
    <Button {...props}>
      <mesh renderOrder={renderOrder}>
        <sphereGeometry />
        <meshBasicMaterial opacity={0} transparent={true} depthWrite={false} />
      </mesh>
    </Button>
  );
}
