import React from "react";
import { Vector2, Vector3, Shader, MeshStandardMaterial } from "three";
import type { StandardMesh } from "../util";
import { useMaterialDuplication } from "../material-duplication";

export type FloatUniform = { value: number; type: "float" };
export type Vec2Uniform = { value: Vector2; type: "vec2" };
export type Vec3Uniform = { value: Vector3; type: "vec3" };
export type CustomUniform = FloatUniform | Vec2Uniform | Vec3Uniform;

export type UniformCollection = Record<string, CustomUniform>;

export type ModificationProps = {
  material: MeshStandardMaterial | null;
  onCompile: (shader: Shader) => void;
  uniforms?: UniformCollection;
};

export function useShaderModification({
  material,
  onCompile,
  uniforms,
}: ModificationProps) {
  React.useEffect(() => {
    if (!material) return;

    material.onBeforeCompile = (shader) => {
      if (uniforms) {
        shader.fragmentShader = shader.fragmentShader.replace(
          "#include <common>",
          `#include <common>
${Object.keys(uniforms)
  .map(
    (name) => `uniform ${uniforms[name].type} ${name};
`
  )
  .join("")}`
        );

        shader.uniforms = {
          ...shader.uniforms,
          ...uniforms,
        };
      }

      onCompile(shader);
    };
  }, [material, onCompile, uniforms]);
}

export type ModificationOnMeshProps = Omit<ModificationProps, "material"> & {
  mesh: StandardMesh;
};

export function useShaderModificationOnMesh({
  mesh,
  ...props
}: ModificationOnMeshProps) {
  const material = useMaterialDuplication(mesh);

  React.useEffect(
    () => void (material.name = `${material.name}-mod`),
    [material]
  );

  useShaderModification({ material, ...props });
}
