import React from "react";
import {
  HotspotContext,
  HotspotContextAmplifier,
  useHotspotContext,
} from "../context";

export type ContextModifierProps = {
  affect: "interactivity" | "visibility";
  additive?: boolean;
  value: boolean;
};

export const ContextModifier: React.FC<ContextModifierProps> = ({
  children,
  affect,
  value,
  additive,
}) => {
  const ctx = useHotspotContext();

  const modifiedScope = React.useMemo<HotspotContext | null>(() => {
    if (!ctx) return null;

    const valueName = affect === "visibility" ? "invisible" : "disabled";
    const originalValue = ctx[valueName];

    return {
      ...ctx,
      [valueName]: additive ? !value && originalValue : !value || originalValue,
    };
  }, [value, ctx, affect, additive]);

  return (
    <HotspotContextAmplifier value={modifiedScope}>
      {children}
    </HotspotContextAmplifier>
  );
};
