import React from "react";

export type PreloadResult = { [key in string]?: any };
export type PreloadFunction<T> = (
  src: string,
  onProgress: (progress: number) => void
) => Promise<T>;
export type PreloadAsset<T> = {
  src: string;
  size: number;
  loader: PreloadFunction<T>;
};

const Context = React.createContext<PreloadResult | null>(null);

export const usePreloadResult = () => React.useContext(Context);

export const PreloadProvider = Context.Provider;
