import React from "react";
import { Object3D } from "three";
import { useSpring, animated } from "@react-spring/three";
import { GroupProps } from "@react-three/fiber";

export type TransformProps = Pick<
  GroupProps,
  "position" | "scale" | "rotation"
>;

export function AnimatedPrimitive({
  object,
  props,
}: {
  object: Object3D;
  props: TransformProps;
}): JSX.Element | null {
  const spring = useSpring<TransformProps>(props);

  return (
    <animated.group {...spring}>
      <primitive object={object} />
    </animated.group>
  );
}
