import React from "react";

import { TreeNodeObject, TreeNodeData } from "./object";

export type TreeContext<T extends TreeNodeData> = {
  registerChild(node: TreeNodeObject<T>): void;
  registerNode: ((node: TreeNodeObject<T>) => void | (() => void)) | null;
};

const Context = React.createContext<TreeContext<TreeNodeData> | null>(null);

export const useTreeContext = () => React.useContext(Context);

export type TreeNodeProps<T extends TreeNodeData> = React.PropsWithChildren<{
  name: string;
  data: T;
  nodeRef?: React.MutableRefObject<TreeNodeObject<T> | null>;
}>;

export function TreeNode<T extends TreeNodeData>({
  children,
  name,
  data,
  nodeRef,
}: TreeNodeProps<T>) {
  const parentCtx = useTreeContext() as TreeContext<T> | null;

  const node = React.useMemo<TreeNodeObject<T>>(
    () => new TreeNodeObject<T>(name),
    [name]
  );
  if (nodeRef) nodeRef.current = node;

  React.useEffect(() => {
    node.data = data;
  }, [data, node]);

  React.useEffect(() => {
    parentCtx?.registerChild(node);
  }, [parentCtx, node]);

  return (
    <Context.Provider
      value={
        React.useMemo<TreeContext<T>>(
          () => ({
            registerNode: parentCtx?.registerNode || null,
            registerChild(child: TreeNodeObject<T>) {
              child.parent = node;
            },
          }),
          [node, parentCtx?.registerNode]
        ) as TreeContext<TreeNodeData>
      }
    >
      {children}
    </Context.Provider>
  );
}
